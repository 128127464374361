import { Injectable } from '@angular/core'
import { TranslocoService } from '@ngneat/transloco'
import { EventService } from './event.service'
import { BehaviorSubject } from 'rxjs'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { getFromPreRender, GlobalService } from './global.service'
import { parseMenuTree, use } from './utils'

@Injectable({ providedIn: 'root' })
export class MenusService implements CanActivate {

  private rawMenus: any[] = null
  private menus: { [name: string]: BehaviorSubject<any[]> } = {}
  private activeProfile: string = null
  private loadedMenus = false
  private fss: FirestoreService2

  constructor(private g: GlobalService, private transloco: TranslocoService) {
    EventService.get('reload-menus').subscribe(() => {
      this.updateMenus()
    })
  }

  /******************
   * PRIVATE METHODS
   ******************/

  /**
   * Atualiza os menus que estão a ser escutados nos componentes
   * @private
   */
  private updateMenus() {
    if (this.rawMenus) {
      for (const menuName in this.menus) {
        if (menuName === 'root') this.menus[menuName].next(this.getACLMenus(''))
        else {
          const menu = this.rawMenus.find(m => m.name === menuName) || null
          if (menu) this.menus[menuName].next(this.getACLMenus(menu.id))
        }
      }
    }
  }

  /**
   * Substitui TAGS especiais nas rotas em tempo de execussão
   * Tags disponíveis
   * [uid] => this.g.user.uid
   * [type] => this.g.type
   * @private
   */
  private replaceTags(menus: any[]) {
    return menus.length ? menus.map(m => ({
      ...m, route: m.route ? m.route
        .replace('[type]', this.g.type)
        .replace('[email]', this.g.user.email)
        .replace('[username]', this.g.user[this.g.program.username])
        .replace('[uid]', this.g.user.uid) : m.route
    })) : []
  }

  /**
   * Cria um PrimeNG MenuModel para preencher o MegaMenu de categorias
   * @private
   */
  private getMegaMenuCategories() {
    const menus: any[] = []
    this.g.get$('categories').subscribe((categories: any[]) => {
    // this.fss2.list('categories', { where: [ [ 'active', '==', true ] ] }).subscribe((categories: any[]) => {
      const roots = categories.filter(c => c.parent === 'HOME')
      for (const root of roots) {
        menus.push({ label: root.name, items: [] })
      }

      for (const first of menus) {
        for (const m of categories.filter(c => c.parentId === first.id)) {
          first.items.push([ { label: m.name, items: categories.filter(c => c.parentId === m.id).map(c => ({ label: c.name })) } ])
        }
      }

      if (this.menus['rewards-menu']) this.menus['rewards-menu'].next(menus)
      else this.menus['rewards-menu'] = new BehaviorSubject(menus)
    })
  }

  /**
   * Devolve um array no padrão de um PrimeNG Treemenu
   * filtrado somente com os menus permitidos ao utilizador
   * baseado em seu profile (role)
   */
  private getACLMenus(firstParent = ''): any[] {
    if (this.rawMenus && this.g.get('profile')) {
      const userMenus = this.rawMenus.filter(m => (this.g.get('profile').access || []).indexOf(m.id) > -1 && m.show).map(m => ({ ...m, name: this.transloco.translate(m.name) }))
      const aclMenus = parseMenuTree(userMenus, [ ...userMenus ], firstParent)
      return aclMenus
    }
    return []
  }

  /*****************
   * PUBLIC METHODS
   *****************/

  /**
   * Fica a escutar atulaizaçóes do perfil do utilizador para aplicar as reglas de ACL aos menus
   * @param user
   */
  setProfile(user: any) {
    // console.log('# setProfile()', user)
    if (user) {
      if (user.role !== this.activeProfile) {
        const api: ApiService = use<ApiService>(ApiService)
        this.activeProfile = user.role
        // this.fss2.getBy('profiles', 'role', user.role).subscribe(prof => {
        api.post('db/action', {
          action: 'profileByRole',
          role: user.role,
          nick: this.g.nick
        }, true).subscribe(prof => {
          this.g.set('profile', prof, true)
          this.updateMenus()
        })
      }
    }
  }

  /**
   * Faz o pre-carregamento dos menus e os guarda em GlobalService
   */
  loadMenus() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (!this.rawMenus && !this.loadedMenus) {
      getFromPreRender('menus', this.fss.list('menus', { api: false, nick: 'yes', where: [ [ 'active', '==', true ] ] })).subscribe((results: any[]) => {
        this.loadedMenus = true
        const menus = this.replaceTags(results)

        if (menus && menus.length) {
          this.g.set('rawMenus', menus, true)
          this.rawMenus = menus
          const aclMenus: any[] = parseMenuTree(menus, [ ...menus ], '')
          const menuObj = {}
          for (const parent of aclMenus) if (parent.items && parent.items.length) menuObj[parent.label] = parent.items.map(i => i.label)
          this.g.set('menus', menuObj, true)
          this.updateMenus()
        }
      })
      this.loadedMenus = true
    }
  }

  /**
   * Devolve um Observavel para escutar um menu especifico
   * Se parentName for vazio ou 'root', devolve um observavel com todos os menus a nivel raiz
   * @param {string} parentName
   * @returns {BehaviorSubject<any>}
   */
  getMenuByParent$(parentName: string): BehaviorSubject<any> {
    if (parentName === 'rewards-menu') this.getMegaMenuCategories()
    if (!this.menus[parentName || 'root'] || this.menus[parentName || 'root'] === undefined) this.menus[parentName || 'root'] = new BehaviorSubject([])
    this.updateMenus()
    return this.menus[parentName || 'root']
  }

  /**
   * Método legado criado por MRS movido para este service
   * Devolve um objecto com FormGroup
   */
  getMenusGrp(): any {
    const menusGrp = {}
    // for (const [ key, value ] of (Object.entries(this.g.get('menus')) as any[])) {
    //   const grp = { enabled: [ false, [ Validators.required as any ] ] }
    //   for (const v of value) {
    //     grp[v] = [ false, [ Validators.required as any ] ]
    //   }
    //   menusGrp[key] = this.g.formBuilder.group(grp)
    // }
    return menusGrp
  }

  /****************************************
   ** Métodos para AuthGuard e Segurança **
   ****************************************/

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const publicUrls = [ '/home' ] // <= defina URL publicas aqui
    const profile = this.g.get('profile')
    const user = this.g.get('user')
    // console.log('#menus canActivate profile', profile)
    const allowed = profile ? [
      ...(this.g.get('rawMenus') || []).filter(r => (profile.access || []).indexOf(r.id) > -1 && r.route).map(r => r.route),
      ...publicUrls
    ] : publicUrls

    // remova o comentario da linha abaixo se quiser tornar hardcoded que um 'admin' pode aceder a todas as rotas
    if ((profile && profile.role === 'admin') || (user && user.role === 'admin')) return true

    return allowed.filter(url => state.url.indexOf(url) > -1).length > 0
  }

}
