import { DOCUMENT } from '@angular/common'
import { GlobalService } from '@y4w-core'
import { Component, Inject, OnInit } from '@angular/core'

@Component({
  selector: 'adblock-modal',
  templateUrl: './adblock-modal.component.html',
  styleUrls: ['./adblock-modal.component.sass']
})
export class AdblockModalComponent implements OnInit {

  display = false

  constructor(
    public g: GlobalService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    if (this.document.getElementById('bait-4-adblock').offsetHeight === 0 && this.g.program && this.g.type === 'rewards') {
      this.display = true
    }
  }

  reload() {
    if (this.g.isBrowser) window.location.reload()
  }

}
