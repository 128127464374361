<div *ngIf="notify.msg | async as msg" class="fixed-bottom notification-component" style="z-index: 50000">

  <!--<div class="btn-group btn-sm pull-right w-100 {{msg.style}}">-->
  <div class="flex w-100 {{msg.style}}">

    <button class="delete btn btn-sm {{msg.style}}" (click)="notify.clear()">
      <i class="fas fa-times"></i>
    </button>

    <button class="btn btn-sm flex-grow-1 {{msg.style}}">
      <b>{{ msg.content }}<br>{{ msg.content2 }}</b>
    </button>

    <a *ngIf="msg.link" class="btn {{msg.style}}" href="{{msg.link?.url}}">
      <i class="fas fa-link">&nbsp;{{msg.link?.name}}</i>
    </a>

  </div>

</div>

<p-dialog *ngIf="modalShow" [modal]="true" [(visible)]="modalShow" [header]="modalData.data.title" [contentStyle]="{padding: 0}">
  <div class="generic-modal-body" [innerHTML]="modalData.data.message"></div>

  <div class="generic-modal-footer">
    <button class="btn btn-primary btn-sm" type="button" (click)="modalResult(null)">{{'close' | transloco}}</button>
  </div>
</p-dialog>
