<div class="m-body p-pt-2">
  <p [innerHTML]="message"></p>
  <div class="form-group p-mb-2">
    <input *ngIf="['text', 'email', 'number', 'tel', 'date'].includes(type)" [type]="type" class="form-control form-control-sm" [(ngModel)]="text">
    <textarea *ngIf="type === 'textarea'" style="min-height: 100px" class="form-control form-control-sm" [(ngModel)]="text"></textarea>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <button type="button" class="btn btn-block btn-sm btn-outline-dark cursor-none" (click)="close()" *ngIf="btnCancelText">{{btnCancelText}}</button>
  </div>
  <div class="col-6">
    <button type="button" class="btn btn-block btn-sm btn-primary" (click)="confirmAction();">{{btnOkText}}</button>
  </div>
</div>
