import { Injectable } from '@angular/core'
import { Toast } from '@capacitor/toast'
import * as Deploy from '@capacitor/live-updates'
import { NgxSpinnerService } from 'ngx-spinner'
import { Cry } from './cry.service'
import { GlobalService } from './global.service'
import { timeOut } from './utils'

let env: any

@Injectable({
  providedIn: 'root'
})
export class SwUpdateService {

  private downloadInProgress = false
  private installPending = false
  private newVersion: number = null
  private newBuildVersion = null

  constructor(private g: GlobalService, private spin: NgxSpinnerService) {
    env = (window as any).y4wenvs.ENV
    // this.getVersions().then(versions => versions.length && console.log('# version', versions[versions.length - 1]))
  }

  checkNativeUpdate(showLoading = false, autoUpdate = false): Promise<boolean> {
    console.log('# checkNativeUpdate')
    // test for SSR
    if (this.g.isBrowser) {
      return new Promise(async resolve => {
        if (this.g.isNative && env.production) {
          try {
            const sync = (await Deploy.sync()) || null
            console.log('# SYNC RES', sync)
            if (sync.activeApplicationPathChanged) {
              // Reload the app; the new version will appear afterward
              this.swlog('Há novo update!')
              this.swlog('Download completo.')
              this.installPending = true
              this.downloadInProgress = false
              if (showLoading) this.spin.hide().then()
              if (autoUpdate) this.updateNow()
              resolve(true)
            }
          } catch (e) {
            console.log('# SYNC ERR', e.message)
            console.log(e)
          }

          /*if (!this.installPending && !this.downloadInProgress) {
            if (showLoading) this.spin.show().then()
            const channel = {
              yes4eutests: 'tests',
              yes4eupp: 'pp',
              yes4eu: 'prod'
            }
            console.log('# live update channel:', `${this.g.nick}-${channel[env.id]}`)
            Deploy.configure({channel: `${this.g.nick}-${channel[env.id]}`}).then(() => {

              Deploy.checkForUpdate().then((update: any) => {
                console.log('# SwUpdate.listenNativeUpdate() -> checkForUpdate() =>', update.available || 'sem update')
                console.log('# update.buildId', update ? update.buildId : null)

                if (update.available && (!update.build || +update.build > +(sync || {buildId: 0}).buildId)) {
                  this.swlog('Há novo update!')
                  this.newBuildVersion = +update.build
                  /!*this.downloadInProgress = true;
                  Deploy.downloadUpdate((progress: number) => {
                    // console.log(progress, '%');
                  }).then(success => {
                    if (success) {
                      this.swlog('Download completo.');
                      this.installPending = true;
                      this.downloadInProgress = false;
                      if (showLoading) this.spin.hide().then();
                      if (autoUpdate) this.updateNow();
                      resolve(true);
                    }
                  }).catch(() => this.downloadInProgress = false);*!/
                  if (autoUpdate) this.downloadNativeUpdate(showLoading, autoUpdate).then(downloaded => resolve(downloaded))
                  resolve(true)
                } else {
                  this.swlog('Não há updates')
                  if (showLoading) this.spin.hide().then()
                  resolve(false)
                }
              }).catch(() => { if (showLoading) this.spin.hide().then(); resolve(false) })
            }).catch(() => { if (showLoading) this.spin.hide().then(); resolve(false) })
          } else {
            console.log('# esta a correr uma atualização agora')
            if (this.installPending) {
              if (autoUpdate) this.updateNow()
              resolve(true)
            }
          }*/
          resolve(false)
        } else {
          if (!this.g.isNative) (window as any).location.reload()
          console.log('# nao é app nativa')
          resolve(false)
        }
      })
    } else return Promise.resolve(false)
  }

  // downloadNativeUpdate(showLoading = false, autoUpdate = false) {
  //   // test for SSR
  //   if (this.g.isBrowser) {
  //     this.downloadInProgress = true
  //     /*return new Promise<boolean>(resolve => {
  //       Deploy.downloadUpdate((progress: number) => {
  //         // console.log(progress, '%');
  //       }).then(success => {
  //         if (success) {
  //           this.swlog('Download completo.')
  //           console.log('# downloadNativeUpdate() => download completo')
  //           this.installPending = true
  //           this.downloadInProgress = false
  //           if (showLoading) this.spin.hide().then()
  //           if (autoUpdate) this.updateNow()
  //           resolve(true)
  //         }
  //       }).catch(() => {
  //         console.log('# downloadNativeUpdate() => erro download')
  //         this.downloadInProgress = false
  //         resolve(false)
  //       })
  //     })*/
  //     return Promise.resolve(false)
  //   } else return Promise.resolve(false)
  // }

  // deleteVersion() {
  //   // Deploy.getAvailableVersions().then(async versions => {
  //   //   if (versions && versions.length && versions.length > 2) {
  //   //     this.swlog(`Há ${versions.length} versões arquivadas.`)
  //   //     for (let i = 0; i < (versions.length - 2); i++) {
  //   //       await Deploy.deleteVersionById(versions[i].versionId)
  //   //     }
  //   //   }
  //   // })
  // }

  // getVersions(): Promise<any> {
  //   // return Deploy.getAvailableVersions()
  //   return Promise.resolve([])
  // }

  // getCurrentVersion(): Promise<any> {
  //   // return Deploy.getCurrentVersion()
  //   return Promise.resolve(null)
  // }

  updateNow() {
    // test for SSR
    if (this.g.isBrowser) {
      this.spin.show().then()
      console.log('# SwUpdate.extractUpdate()')
      Deploy.reload().then(() => {
        this.spin.hide().then()
        Cry.setLocal('liveBuild', this.newBuildVersion, true)
        console.log('## App atualizado!')
        this.swlog('App atualizado')
        this.installPending = false
      }).catch(() => { this.installPending = false; this.spin.hide().then() })

      /* Deploy.extractUpdate((progress: number) => {
        // console.log(progress, '%');
      }).then(success => {
        // this.spin.hide().then();
        if (success) {
          console.log('# SwUpdate.reloadApp()')
          Deploy.reloadApp().then(updated => {
            this.spin.hide().then()
            if (updated) {
              Cry.setLocal('liveBuild', this.newBuildVersion, true)
              console.log('## App atualizado!')
              this.swlog('App atualizado')
              this.installPending = false
              this.deleteVersion()
            }
          }).catch(() => { this.installPending = false; this.spin.hide().then() })
        }
      }).catch(e => {
        console.log('# erro extract update', e.message)
        this.spin.hide().then()
      }) */
    }
  }

  swlog(text: string) {
    if (this.g.user.role === 'admin') Toast.show({ text }).then()
  }

  listenLiveVersions(subs: any, fss2: any, notify: any, transloco: any) {
    // test for SSR
    if (this.g.isBrowser) {
      subs.add(
        fss2.list('versions', { orderBy: 'createdAt', direction: 'desc', limit: 10, where: [['type', '==', 'live-update']]}).subscribe((versions: any[]) => {
          // console.log('* versions', versions);
          if (versions && versions instanceof Array && versions.length) {
            const oldVersion = Cry.getLocal('lov', true) || null
            if (oldVersion !== null) {
              if (+oldVersion < +versions[0].version) {
                // console.log('# ', +oldVersion < +versions[0].version);
                this.newVersion = +versions[0].version
                timeOut(() => {
                  notify.confirm(
                    transloco.translate('update_available'),
                    `<p>${transloco.translate('new_system_update_message')}</p><p>${transloco.translate('version')}: <strong>${this.newVersion}</strong></p><p>${transloco.translate('new_version_action_message')}</p>`,
                    transloco.translate('update'), transloco.translate('await')
                  ).then(result => {
                    if (result) {
                      Cry.set('lnv', `${this.newVersion}`, true)
                      this.checkNativeUpdate(true, true)
                    }
                  })
                }, 2000)
              }
            } else {
              Cry.setLocal('lov', versions[0].version, true)
            }
          }
        }, er => console.log('# er version', er.message))
      )
    }
  }

  refresh() {
    Deploy.reload()
  }
}
