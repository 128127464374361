import { Injectable } from '@angular/core'
import { first } from 'rxjs/operators'
import { ApiService } from './api.service'
import { GlobalService, replaceStringTags } from './global.service'
import { use } from './utils'

let env: any

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private g: GlobalService) {
    console.info(`## ${this.constructor.name}`)
    env = (window as any).y4wenvs.ENV
  }

  /**
   * Envia um único SMS
   * @param user O objecto user que receberá o SMS
   * @param message A mensagem a ser enviada
   *
   * A mensagem pode conter as tags a serem substituidas no momento do envio:
   * {{NAME}}, {{FIRST_NAME}}, {{PROGRAM}}, {{SEGMENT}}, {{EMAIL}}, {{POINTS}}
   */
  sendOne(user: any, message: string): Promise<any> {
    const api = use<ApiService>(ApiService)
    return api.post('sms/sendSms', {
      to: this.formatNumber(user.mobile),
      message: replaceStringTags(user, message, this.g),
      a: env.bulkSMS.auth,
      url: `${env.bulkSMS.url}/messages`
    }).pipe(first()).toPromise()
  }

  /**
   * Envia vários SMSs
   * @param users O Array de objectos user que receberão o SMS
   * @param message A mensagem a ser enviada
   *
   * A mensagem pode conter as tags a serem substituidas no momento do envio:
   * {{NAME}}, {{FIRST_NAME}}, {{PROGRAM}}, {{SEGMENT}}, {{EMAIL}}, {{POINTS}}
   */
  sendMany(users: any[], message: string): Promise<any> {
    const items = users.map(u => ({to: this.formatNumber(u.mobile), message: replaceStringTags(u, message, this.g)}))
    const api = use<ApiService>(ApiService)
    return api.post('sms/sendManySms', {
      a: env.bulkSMS.auth,
      url: `${env.bulkSMS.url}/messages`,
      items
    }).pipe(first()).toPromise()
  }

  /**
   * Formata um número de telemovel para incluir o +351 e remover caracteres não numéricos
   * @param mobile O número do telemóvel
   * @private
   */
  private formatNumber(mobile: string | number) {
    return '+351' + `${mobile}`.replace(/\D/g, '')
  }
}
