import { Injectable } from '@angular/core'
import { CalendarEvent, CalendarEventAction } from "angular-calendar"
import orderBy from 'lodash-es/orderBy'
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

export enum EventDetailType {
  TASK = 'TASK',
  VISIT = 'VISIT',
  APPOINTMENT = 'APPOINTMENT',
  GOAL = 'GOAL',
  CHALLENGE = 'CHALLENGE',
  EXPIRED_GOAL = 'EXPIRED_GOAL',
  APPROVE_POINTS = 'APPROVE_POINTS',
  USER_VERIFICATION = 'USER_VERIFICATION'
}

export enum EventDetailIcon {
  TASK = 'fa-tasks',
  VISIT = 'fa-arrow-circle-o-right',
  APPOINTMENT = 'fa-users',
  GOAL = 'fa-check'
}

export enum EventType {
  CALENDAR = 'CALENDAR',
  GOAL = 'GOAL',
  CHALLENGE = 'CHALLENGE',
  CHAT = 'CHAT',
  ALERT = 'ALERT'
}

export enum EventColor {
  TASK = 'var(--cyan)',
  VISIT = 'var(--primary)',
  APPOINTMENT = 'var(--yellow)'
}

export interface IEvent extends Partial<CalendarEvent> {
  active: boolean
  createdAt: string
  updatedAt: string
  eventId: string
  text: string
  users: any[],
  task: any,
  store: any,
  eventType: EventDetailType
  type: EventType
  startTimestamp: number
  endTimestamp: number
  detail: any
  user: string
  icon: string
  backgroundColor: string
  borderColor: string
}

@Injectable({
  providedIn: 'root'
})
export class CalendarsService {

  private fss: FirestoreService2

  constructor(public g: GlobalService) { console.info(`## ${this.constructor.name}`) }

  loadEvents(today = new Date()): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const where = [
      ['active', '==', true],
      ['type', '==', EventType.CALENDAR],
      ['startTimestamp', '>=', new Date(today.getFullYear(), today.getMonth(), 1).getTime()],
      ['user', '==', this.g.user.uid]
    ]
    return this.fss.list('events', { where }).pipe(
      map((evts: any[]) => evts.map(e => ({ ...e, backgroundColor: e.color.primary, borderColor: e.color.secondary })))
    )
  }

  loadEvent(id: string): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.get(`events`, id)
  }

  loadEventsByStore(storeId: string): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.list(`events`, { where: [['active', '==', true], ['eventId', '==', storeId]], orderBy: 'startTimestamp' })
  }

  loadStoresByUser(): Observable<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.list('stores', {
      where: this.g.user.role === 'user' ? [['active', '==', true], ['user', '==', this.g.user.email]] : [['active', '==', true]]
    }).pipe(map(stores => orderBy(stores, ['name'])))
  }

  loadUsers(): Observable<any> {
    const api = use<ApiService>(ApiService)
    return api.post(`db/action`, { action: 'list', nick: this.g.nick, collection: 'users', take: 0 }, true).pipe(map((users: any) => users.filter(user => user.uid !== this.g.user.uid)))
  }

  deleteEvent(event: Partial<IEvent>): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.merge(`events`, event.id as string, { active: false, id: event.id })
  }

  deleteItemEvent(event: Partial<IEvent>): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const collecations = {
      TASK: 'tasks'
    }
    return this.fss.merge(collecations[event.eventType], event.eventId as string, { active: false, id: event.eventId })
  }

  addEvent(event: Partial<IEvent>): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const newEvent: Partial<IEvent> = {
      active: true,
      type: EventType.CALENDAR,
      color: {
        primary: EventColor[event.eventType],
        secondary: EventColor[event.eventType]
      },
      cssClass: event.eventType,
      user: this.g.user.uid,
      ...event
    }
    if (newEvent.eventType === EventDetailType.TASK) {
      newEvent.user = newEvent.task.assignees.length ? newEvent.task.assignees[0].id : newEvent.user
    }
    this.addEventToUsers(newEvent)
    newEvent.id = newEvent.id || this.fss.createId
    return this.fss.merge(`events`, newEvent.id, newEvent)
  }

  private addEventToUsers(event: Partial<IEvent>) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    if (event && event.users && event.users instanceof Array) {
      const users = [...event.users]
      const newEvent = { ...event, id: this.fss.createId }
      delete newEvent.users

      for (const user of users) {
        this.fss.merge(`events`, newEvent.id, { ...newEvent, user: user.uid })
      }
    }
  }

  getGenericActions(parent: any, editAction: Function = null, deleteAction: Function = null, viewAction: Function = null): CalendarEventAction[] {
    const actions: CalendarEventAction[] = []

    if (editAction !== null) {
      actions.push({
        label: '<i class="fas fa-fw fa-pencil text-white"></i>',
        a11yLabel: 'Editar',
        onClick: ({ event }: { event: CalendarEvent }): void => editAction(event, parent)
      })
    }

    if (deleteAction !== null) {
      actions.push({
        label: '<i class="fas fa-fw fa-times-square text-danger"></i>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => deleteAction(event, parent)
      })
    }

    if (viewAction !== null) {
      actions.push({
        label: '<i class="fas fa-fw fa-eye text-white"></i>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => viewAction(event, parent)
      })
    }

    return actions
  }

  saveUserHours(dayStartHour: number, dayEndHour: number) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.merge(`users`, this.g.user.uid, { dayStartHour, dayEndHour, createdAt: this.g.user.createdAt, createdBy: this.g.user.createdBy })
  }

  getUserHours(): any {
    if (this.g.user && this.g.user.dayStartHour && this.g.user.dayEndHour) {
      return { dayStartHour: this.g.user.dayStartHour, dayEndHour: this.g.user.dayEndHour }
    }
    return { dayStartHour: 6, dayEndHour: 21 }
  }

}
// 8648-1615-8868
