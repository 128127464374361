export const env = {

  production: false,
  pp: false,
  id: 'yes4eutests',
  region: 'europe-west3',
  propertyId: '268427811',

  _yesKey: 'dGhlIG9sYXJpYSBzZXJ2aWNlIGtleQ==',

  // yes4eutests
  firebaseConfig: {
    apiKey: "AIzaSyCqSOQwtaED9QTOg_IqZxOKgF5N89zO2lQ",
    authDomain: "yes4eutests.firebaseapp.com",
    projectId: "yes4eutests",
    storageBucket: "yes4eutests.appspot.com",
    messagingSenderId: "1062997613231",
    appId: "1:1062997613231:web:a93d4d8f5b49f3ab41125a",
    measurementId: "G-T322ZYB8NT"
  },
  pk: '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAugoV8b11YJIiCwLzxjpi\nODXX/EAQRVBVX0y69Rli3GoC3+vHW/yrjRqbeNj9+f6Iyh05ncu34z0Y4UB2Zf4p\nv3aMOjNlpgnT5TeAe/GBp66v7UnRNpG/47CflUSbJnhhb1UYqbrhhbalHEsCujit\nvz96KdGaghtB3Z9QEk5M1/nEtgwBg2xqaGoiKhn4NsGpVGtRZOWX4pHIAhwiKvaD\nmSx9Pwyn7IBVY2ccJnIyo/yJbZjK/f6qbmaUhzb1wBB8b6MrgfaPv5VG7WfMRPca\nyYNw2WxsJZvRu/Dj4aDg/ahW5lRDnyD8mxbiD5v+8FP/7RR+kaxIB6uOBreoGqqQ\naQIDAQAB\n-----END PUBLIC KEY-----',

  _cryKey: 'JpYS',

  stripePublishable: 'pk_test_8WLlgoMFsLnKWW1oQG7BVHQ8',

  algolia: {
    appId: '3M8JWUF0PM',
    apiKey: '5501a4a9e7f84050a292cdb5fa22bb12'
  },

  infobip: {
    url: 'https://api.infobip.com/'
  },

  _yesSalt: 'dGhlIG9sYXJpYSBzYWx0ZSBrZXkgc2VydmljZQ==',

  easyPayUrl: 'https://api.test.easypay.pt/2.0/',
  easyPayId: '7cb9b8de-28f6-4c87-aca0-f2afb0f733d7',
  easyPayKey: '6a32abbe-f69e-4693-a010-90605061ceb1',

  webPushKey: 'BE8ieIBoxsi_paCZKszWDmOEXZ-ttqclJbDI55pc88vUAZY2B8xqKbLBCpWyCOMThCLfIjbbSrmyDkLQDCelMLk',

  msAdTenant: 'e7f9d69c-13f3-457c-a04c-f555c1134fa4', // AD pp
  msAdClient: 'bcafd1ab-b988-4fd8-9b1e-98771f5453cb', // AD pp
  msRedirect: 'https://somos-pp.yesmkt.net/login/token',
  // msAdTenant: '63a805d9-3082-43f9-a16a-7b6715ec3bd7', // AD yes
  // msAdClient: 'f191fec1-da2b-41c2-aa13-e655a90b3167', // AD yes
  // msRedirect: 'https://somos-pp.loca.lt/login/token',

  bulkSMS: {
    name: 'yes4eutests',
    url: 'https://api.bulksms.com/v1',
    auth: 'Basic NjM5RUZBNDU3NTI1NDNCNzkxNEI3MjVBMkQ0MTM2NzYtMDItQjpYdyNfKktiOVRMaTNqb0JDOFV4VmpIVXhmS0NQZg=='
  }

};
