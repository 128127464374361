import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Location } from '@angular/common'
import firebase from "firebase/app"
import "firebase/analytics"
import { GlobalService } from './global.service'

export interface IAnalyticsDatesConfig {
  start: string,
  end?: string
}

export interface IAnalyticsConfig {
  propertyId: string,
  dates: IAnalyticsDatesConfig,
  dimensions: string[],
  metrics: string[],
  filter?: any
}

let env: any

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  private enabled: boolean
  private analytics: any = null

  constructor(
    private location: Location,
    private router: Router,
    private g: GlobalService) {
    this.enabled = false
    console.info(`## ${this.constructor.name}`)

    // test for SSR
    if (this.g.isBrowser) {
      env = (window as any).y4wenvs.ENV
      try {
        // console.log('* Analytics starting firebase if no present');
        if (env.firebaseConfig) {
          firebase.initializeApp(env.firebaseConfig)
        } else throw Error('Firebase não foi iniciado')
      } catch (e) {
        if (e.message.indexOf('already exists') === -1) console.log('** ERROR', e.message)
      }

      this.analytics = firebase.analytics()
    }
  }

  trackPageViews() {
    // test for SSR
    if (this.g.isBrowser) {
      const ga = (window as any).ga
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.enabled) ga('send', {hitType: 'pageview', page: this.location.path()})
          if (this.analytics) this.analytics.logEvent(`${this.g.nick} NAV ${event.url}`, { url: event.url, nick: this.g.nick })
        }
      })
    }
  }

  trackCustomEvent(eventName: string, extraData: any = {}) {
    // test for SSR
    if (this.g.isBrowser) {
      const ga = (window as any).ga
      if (this.enabled) ga('send', 'event', eventName)
      if (this.analytics) this.analytics.logEvent(`${this.g.nick} ${eventName}`, { ...extraData, nick: this.g.nick })
    }
  }

  trackClickEvent(eventName: string, extraData: any = {}) {
    this.trackCustomEvent(`CLICK ${eventName}`, extraData)
  }

  getReport(config: IAnalyticsConfig) {
    if (config && false) {
      const request: any = {
        propertyId: config.propertyId,
        dateRanges: [ { startDate: config.dates.start, endDate: config.dates.end || "today" } ],
        dimensions: config.dimensions.map(d => ({ name: d })),
        metrics: config.metrics.map(m => ({ name: m }))
      }
      if (config.filter) request.dimensionFilter = config.filter
      // const api: ApiService = use<ApiService>(ApiService)
      // return api.post('analytics/report', request).pipe(/*tap(console.log), */map(values => ({
      //   data: values.data/*.filter(r => r.dimension.startsWith(this.g.nick))*/.map(r => ({...r, dimension: r.dimension.replace(`${this.g.nick} `, '')})),
      //   table: values.table.filter(r => r.dimension.startsWith(this.g.nick)).map(r => ({...r, dimension: r.dimension.replace(`${this.g.nick} `, '')}))
      // })))
    } else throw new Error('config <IAnalyticsConfig> must be provided')
  }

  getReport2(config: IAnalyticsConfig) {
    // const request: any = {
    //   dateRanges: [ { startDate: config.dates.start, endDate: config.dates.end || "today" } ],
    //   dimensions: config.dimensions.map(d => ({ name: d })),
    //   metrics: config.metrics.map(m => ({ name: m }))
    // }
    // if (config.filter) request.dimensionFilter = config.filter
    // const api: ApiService = use<ApiService>(ApiService)
    // return api.post(`https://content-analyticsdata.googleapis.com/v1beta/properties/${config.propertyId}:runReport?alt=json`, request).pipe(map(report => {
    //   const data = []
    //   const table = []
    //   report[0].rows.forEach(r => {
    //     const res_ = {
    //       dimension: r.dimensionValues[0].value,
    //       metrics: []
    //     }
    //     let row = {}
    //     request.metrics.forEach((metric, i) => {
    //       res_.metrics.push({ name: metric.name, value: r.metricValues[i].value })
    //       row = { ...row, dimension: r.dimensionValues[0].value, [metric.name]: r.metricValues[i].value }
    //     })
    //     data.push(res_)
    //     table.push(row)
    //   })
    //   return { data, table }
    // }))
  }
}
