import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlgoliaHitsPipe } from './algoliaHits.pipe'
import { FilterPipe } from './filter.pipe'
import { SafeHtmlPipe } from './safeHtml.pipe'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SafeHtmlPipe,
    FilterPipe,
    AlgoliaHitsPipe
  ],
  exports: [
    SafeHtmlPipe,
    FilterPipe,
    AlgoliaHitsPipe
  ],
  providers: []
})
export class PipesModule { /* WG */}
