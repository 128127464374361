import { GatewayPartner, IPaymentBase2, PaymentMethod } from '../payment.interface'
import { PaymentService } from '../payment.service'
import { PaymentBase } from './payment.base'
import { Observable } from 'rxjs'

// declare var braintree: any;

export class PaymentBraintree extends PaymentBase implements IPaymentBase2 {

  private token: string = null
  private nonce: string = null
  private deviceInfo: any = null

  constructor(paymentSrv: PaymentService) {
    super()
    this._paymentSrv = paymentSrv
  }

  createInstance(payment: { value: number; method: PaymentMethod }, user: any, paymentConfig: any): PaymentBraintree {
    const id = this._paymentSrv.generatePaymentID()
    this.currency = paymentConfig.currency
    this.value = +payment.value.toFixed(2)
    this.method = payment.method as PaymentMethod
    this.paymentID = id
    this.partner = GatewayPartner.BRAINTREE
    this.token = paymentConfig.tokenKey
    this.useLibrary = paymentConfig.useLibrary

    this.getDeviceInfo()
    return this
  }

  createNewPayment(user: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.nonce) {
        this._paymentSrv.requestNewPayment(user, this, this.paymentID).then((payment: IPaymentBase2) => {
          this.gatewayData = payment.getGatewayData()
          resolve(this.processResult())
        }).catch(err => reject(err))
      } else resolve(this)
    })
  }

  dispatchSuccessHook(api: any, data: any) {
    // console.log('# data', data)
    api.post('opayment/receiveBraintree', { paymentID: this.paymentID, success: true, transaction: data }).toPromise()
  }

  getConfig(): any {
    return {
      partner: this.partner,
      method: this.method,
    }
  }

  private getDeviceInfo() {
    let braintree = null
    if (document && (document as any).defaultView && (document as any).defaultView.braintree) {
      braintree = (document as any).defaultView.braintree
    }
    braintree.client.create({
      authorization: this.token
    }).then(client => {
      if (client) {
        braintree.dataCollector.create({
          client: client,
          paypal: true
        }).then(data => this.deviceInfo = data ? data.deviceData || null : null)
      }
    })
  }

  getMBReference(): { entity: string; reference: string } {
    return { entity: '', reference: '' }
  }

  getPayload(): any {
    const obj = {
      amount: this.value.toFixed(2),
      currency: this.currency.toLowerCase(),
      deviceData: this.deviceInfo,
      nonce: this.nonce
    }
    return JSON.parse(JSON.stringify(obj))
  }

  getPaymentLink(program: any): string {
    if (!program) throw new Error('PROGRAM must be provided')
    else return /*this.gatewayData.method.url ||*/ program.url
  }

  getTransactionStatus(): { paymentDate: string, partner: string } {
    if (this.gatewayData.transaction && this.gatewayData.transaction.transaction && this.gatewayData.transaction.transaction.values) {
      const transaction = this.gatewayData.transaction.transaction
      if (+transaction.values.paid) {
        return {
          paymentDate: transaction.date,
          partner: GatewayPartner.BRAINTREE
        }
      } else console.log('# sem pagamento')
    }
    return null
  }

  hasFirstPayload(): boolean {
    return true
  }

  hasMBWPaid(): boolean {
    return false
  }

  onRedirect(data: any): Observable<{ success: boolean; transaction: any }> {
    return undefined
  }

  processResult(data: any = null): any {
    return this
  }

  setMBWPhone(phone: string): void { }

  setResult(data: any) {
    // console.log('#setResult', data);
    if (data.nonce) {
      this.nonce = data.nonce
    }
    this.gatewayData = data
  }

}
