<div class="m-body p-pt-3">
  <p [innerHTML]="message" style="font-size: 1rem"></p>
</div>
<div class="row">
  <div class="col-6">
    <button type="button" class="btn btn-block btn-sm btn-outline-dark cursor-none" (click)="close()" *ngIf="btnCancelText">{{btnCancelText}}</button>
  </div>
  <div class="col-6">
    <button type="button" class="btn btn-block btn-sm btn-primary" (click)="confirmAction();">{{btnOkText}}</button>
  </div>
</div>
