import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EventService } from './event.service'
import FireStoreParser from 'firestore-parser'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, of } from 'rxjs'
import { catchError, first, map, tap } from 'rxjs/operators'
import { Cry } from './cry.service'
import { GlobalService } from './global.service'

let env: any

@Injectable({ providedIn: 'root' })
export class FirestoreApiService {

  env = (window as any).y4wenvs.ENV
  private baseUrl = 'https://firestore.googleapis.com/v1/projects'
  private projectId: string
  private showConsoles = false

  constructor(private http: HttpClient, private spin: NgxSpinnerService, private g: GlobalService) {
    console.info(`## ${this.constructor.name}`)
    env = (window as any).y4wenvs.ENV
    this.projectId = env.id
    const isDebug = localStorage.getItem('debug')
    this.showConsoles = env.production ? !!isDebug : !env.production && true
  }

  private getFieldType(value: any) {
    if (typeof value === 'boolean') return 'booleanValue'
    if (typeof value === 'string') return 'stringValue'
    if (!isNaN(value)) return 'integerValue'
    if (value instanceof Array) return 'arrayValue'
    return 'stringValue'
  }

  private getFieldValue(value: any): any {
    if (value instanceof Array) return { values: value.map(v => ({ [this.getFieldType(v)]: this.getFieldValue(v) })) }
    return value
  }

  setBaseUrl(url: string): void {
    this.baseUrl = url
  }

  setProjectId(id: string): void {
    this.projectId = id
  }

  list(collectionPath: string, args: any) {
    this.showConsoles && console.groupCollapsed(`%c[FSS] using API list ${collectionPath}`, 'color: orange')
    this.showConsoles && console.log('ARGS:', args)
    this.showConsoles && console.groupEnd()

    // se for um user ser permissões, consulta via api crypted
    if ((!this.g.user || !this.g.user.role || !['admin', 'manager'].includes(this.g.user.role)) && ['transactions', 'users', 'prospects'].includes(collectionPath.split('/')[0])) {
      return new Observable<any[]>(obs => {
        this.listViaApi(collectionPath.split('/')[0], args).then((r: any) => {obs.next(r); obs.complete()}).catch(e => obs.error(e))
      })
    }

    const s = collectionPath.split('/')
    const c = s.pop()
    const u = `${this.baseUrl}/${this.projectId}/databases/(default)/documents${s.length ? '/' + s.join('/') : ''}:runQuery`
    const ops = {
      '==': 'EQUAL',
      '!=': 'NOT_EQUAL',
      '<': 'LESS_THAN',
      '<=': 'LESS_THAN_OR_EQUAL',
      '>': 'GREATER_THAN',
      '>=': 'GREATER_THAN_OR_EQUAL',
      'array-contains': 'ARRAY_CONTAINS',
      'in': 'IN'
    }
    const directions = {
      asc: 'ASCENDING',
      ASC: 'ASCENDING',
      desc: 'DESCENDING',
      DESC: 'DESCENDING'
    }
    const structuredQuery: any = { from: { collectionId: c } }

    if (args) {
      if (args.group) structuredQuery.from.allDescendants = true
      if (args.select && args.select.length) structuredQuery['select'] = { fields: args.select.map(s => ({ fieldPath: s })) }
      if (args.where && args.where.length) structuredQuery['where'] = { compositeFilter: { filters: args.where.map(w => ({ fieldFilter: { field: { fieldPath: w[0] }, op: ops[w[1]], value: { [this.getFieldType(w[2])]: this.getFieldValue(w[2]) } } })), op: 'AND' } }
      if (args.orderBy) structuredQuery['orderBy'] = { field: { fieldPath: args.orderBy }, direction: args.direction ? directions[args.direction] : directions.asc }
      if (args.limit) structuredQuery['limit'] = +args.limit
      if (args.offset) structuredQuery['offset'] = +args.offset
    }

    return this.http.post(u, { structuredQuery })
      .pipe(catchError(err => { this.spin.hide(); console.log('[FSS] ERROR', err.error ? err.error[0].error.message : err, collectionPath); EventService.get('fss-error').emit(err.error ? err.error[0].error.message : err); return of([]) }))
      .pipe(map((_a: any[]) => (_a.length ? _a.filter(r => r.document) : []).map(_r => (_r.document && !_r.document.fields) ? false : ({ ...FireStoreParser(_r.document.fields, _r.document.name || null), id: _r.document.name.split('/').pop() })).filter(_r => _r)))
  }

  get(collectionPath: string, id: string) {
    this.showConsoles && console.groupCollapsed(`%c[FSS] using API get ${collectionPath}/${id}`, 'color: orange')
    this.showConsoles && console.log('ID:', id)
    this.showConsoles && console.groupEnd()

    return this.http.get(`${this.baseUrl}/${this.projectId}/databases/(default)/documents/${collectionPath}/${id}`)
      .pipe(catchError(err => {
        return of({})
      }))
      .pipe(map((res: any) => res.fields ? FireStoreParser(res.fields) : null))
  }

  updateViaApi(collection, id, data, args) {
    return this.http.post(`${this.g.apiUrl}/db/action`, Cry.crypt(JSON.stringify({
      action: 'update',
      collection,
      id,
      nick: args && args.nick ? args.nick : this.g.nick,
      email: (this.g.user || {email: 'indefinido'}).email,
      data
    }), true)).toPromise()
  }

  setViaApi(collection, id, data, args) {
    return this.http.post(`${this.g.apiUrl}/db/action`, Cry.crypt(JSON.stringify({
      action: 'set',
      collection,
      id,
      nick: args && args.nick ? args.nick : this.g.nick,
      email: (this.g.user || {email: 'indefinido'}).email,
      data
    }), true)).toPromise()
  }

  mergeViaApi(collection, id, data, args) {
    return this.http.post(`${this.g.apiUrl}/db/action`, Cry.crypt(JSON.stringify({
      action: 'merge',
      collection,
      id,
      nick: args && args.nick ? args.nick : this.g.nick,
      email: (this.g.user || {email: 'indefinido'}).email,
      data
    }), true)).toPromise()
  }

  listViaApi(collection, args) {
    return this.http.post(`${this.g.apiUrl}/db/action`, Cry.crypt(JSON.stringify({
      action: 'list',
      collection,
      ...args,
      nick: args && args.nick ? args.nick : this.g.nick,
    }), true)).pipe(first(), map((res: any) => JSON.parse(Cry.decrypt(res, true)))).toPromise()
  }
}
