import { FirestoreService2 } from './firestore.service2'
import { Injectable } from '@angular/core'
import { AsyncValidator, ValidationErrors } from "@angular/forms"
import { of } from "rxjs"
import { Observable } from 'rxjs'
import { catchError, debounce, first, map, tap } from "rxjs/operators"
import { use } from './utils'

@Injectable({ providedIn: 'root' })
export class ValidationService implements AsyncValidator {
  constructor() { console.info(`## ${this.constructor.name}`) }

  validate(params): Observable<ValidationErrors | null> {
    const fss2 = use<FirestoreService2>(FirestoreService2)
    return of(fss2.list(params.collection, {where: [[params.field, '==', 'params.value']], take: 1})
      .subscribe(res => { console.log(res[0]); return res[0] ? { unique: true } : null }))
  }
}
