import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { GlobalService } from '../global.service'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[isDev]'
})
export class IsDevDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private g: GlobalService) {
  }

  ngOnInit() {
    const devs = ['waltergandarella@yesmkt.com', 'pedroferreiro@yesmkt.com', 'diogosampaio@yesmkt.com']
    if (devs.includes(this.g.user.email)) this.viewContainer.createEmbeddedView(this.templateRef)
    else this.viewContainer.clear()
  }

}
