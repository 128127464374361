import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })

export class DashboardService {
  nick
  private fss: FirestoreService2

  constructor(public g: GlobalService) { console.info(`## ${this.constructor.name}`) }

  getData() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.list(`charts`, {where: [['active', '==', true]]})
  }

  getResults(chart) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const resultsFrom = chart.resultsFrom
    if (resultsFrom.collection) {
      const where = []
      if (resultsFrom.whereFilter && resultsFrom.whereFilter instanceof Array) {
        for (const wh of resultsFrom.whereFilter) {
          where.push([wh.field, wh.operation, wh.value])
        }
      }
      return this.fss.list(resultsFrom.collectionName, {where: where}).pipe(map(data => {
        const results = []
        const counter = {}
        data.forEach(function (obj) {
          const key = obj[resultsFrom.keyAtribute]
          counter[key] = (counter[key] || 0) + 1
        })
        Object.keys(counter).forEach(key => { results.push({ name: key, value: counter[key] }) })
        return results
      }))

    } else if (resultsFrom.document) {

    }
  }

}
