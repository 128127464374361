import { Router } from '@angular/router'
import { GlobalService } from './global.service'
import { Injectable, Injector } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Observable, of, throwError } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { NotifyService } from './notifications/notify.service'
import { TranslocoService } from '@ngneat/transloco'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  auth: AuthService

  constructor(
    private inj: Injector,
    public g: GlobalService,
    private notify: NotifyService,
    private transloco: TranslocoService,
    private router: Router
  ) { console.info(`## ${this.constructor.name}`) }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('WebApi') > -1) {
      // get token Primavera
    } else if (
      request.url.indexOf('open') > -1 ||
      request.url.indexOf('youtube') > -1 ||
      request.url.indexOf('graph.microsoft.com') > -1 ||
      request.url.indexOf('bsx-ts.ocidentalgrupo.pt') > -1 ||
      request.url.indexOf('login.microsoftonline.com') > -1 ||
      request.url.indexOf('generated.photos') > -1 ||
      request.url.indexOf('shop') > -1 ||
      request.url.indexOf('site') > -1 ||
      request.url.indexOf('oauthCallback') > -1 ||
      request.url.indexOf('resetPassword') > -1 ||
      request.url.indexOf('isRegistered') > -1 ||
      request.url.indexOf('/assets/') > -1 ||
      request.url.indexOf('noAuthRequest') > -1 ||
      request.url.indexOf(`programs/${this.g.get('nick')}`) > -1
    ) {
      return next.handle(request)
        .pipe(catchError(err => {
          if (err && request.url.toLowerCase().indexOf(this.g.apiUrl) > -1 && ['pontonos', 'nosdesafia', 'familiaeamigos', 'tecmais'].indexOf(this.g.nick) > -1 && request.url.indexOf('noError') === -1) {
            // faz alguma coisa aqui para avisar ao utilizador que a rede esta a bloquear
            this.notify.alert('Erro!', this.transloco.translate('token_network_error'))
          }
          return throwError(err)
        }))
    }

    this.auth = this.inj.get(AuthService) // inject the authservice manually (see https://github.com/angular/angular/issues/18224)

    return this.auth.getUserIdToken().pipe(
      tap(token => {
        if (!token && !this.g.program.logins.anonymous) {
          this.g.set('user', null)
          this.router.navigate([ '/login' ])
          // this.notify.alert('Erro', 'Sua sessão expirou, é necesário refazer o login.')
        }
      }),
      switchMap(token => {
        if (!token) return next.handle(request)
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
        // console.log(request)
        return next.handle(request)
          .pipe(catchError(err => {
            if (err && request.url.toLowerCase().indexOf(this.g.apiUrl) > -1 && request.url.indexOf('noError') === -1) {
              // faz alguma coisa aqui para avisar ao utilizador que a rede esta a bloquear
              this.notify.alert('Erro!', this.transloco.translate('token_network_error'))
            }
            return throwError(err)
          }))
      })
    )
  }
}
