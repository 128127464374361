import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Observable, of, ReplaySubject } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoadScriptsService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {}

  constructor(@Inject(DOCUMENT) private readonly document: any) { console.info(`## ${this.constructor.name}`) }

  /**
   * Faz o carregamento sob demanda da biblioteca externa
   * @param url
   */
  loadScript(url: string): Observable<any> {
    /** Caso o script já tenha sido carregado, devolve o que já existe **/
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable()
    }

    this._loadedLibraries[url] = new ReplaySubject()

    const script = this.document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = () => {
      this._loadedLibraries[url].next(true)
      this._loadedLibraries[url].complete()
    }

    this.document.body.appendChild(script)

    return this._loadedLibraries[url].asObservable().pipe(tap(() => null/*!env.production && console.log(`LOADED SCRIPT ${url}`)*/));
  }

  /**
   * Faz o carregamento sob demanda da css externa
   * @param url
   */
  loadStyle(url: string): Observable<any> {
    /** Caso o style já tenha sido carregado, devolve o que já existe **/
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable()
    }

    this._loadedLibraries[url] = new ReplaySubject()

    const head = this.document.querySelector('head')
    const link = this.document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.async = true
    link.href = url
    link.onload = () => {
      this._loadedLibraries[url].next(true)
      this._loadedLibraries[url].complete()
    }

    // this.document.body.appendChild(link)
    head.appendChild(link)

    return this._loadedLibraries[url].asObservable() // .pipe(tap(() => !env.production && console.log(`LOADED STYLE ${url}`)))
    // return of(true)
  }
}
