import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { first } from 'rxjs/operators'
import { ApiService } from './api.service'
import { ImageResult, ResizeOptions } from './directives'
import { NotifyService } from './notifications/notify.service'
import firebase from "firebase/app"
import "firebase/storage"
import { GlobalService } from './global.service'
import { NgxSpinnerService } from 'ngx-spinner'

function s2ab(s: string): ArrayBuffer {
  const buf: ArrayBuffer = new ArrayBuffer(s.length)
  const view: Uint8Array = new Uint8Array(buf)
  // tslint:disable-next-line: no-bitwise
  for (let row = 0; row !== s.length; ++row) view[row] = s.charCodeAt(row) & 0xFF
  return buf
}

let env: any

export interface IResize {
  width: number,
  height: number
}

@Injectable({ providedIn: 'root' })
export class UploadService {

  private storage: any = null
  private extensions = {
    'image/jpeg': '.jpg',
    'image/jpg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/webp': '.webp',
    'image/svg+xml': '.svg+xml',
  }
  private resizeOptions: ResizeOptions
  private resizeAreaId: string

  constructor(
    @Inject(DOCUMENT) private document: any,
    private notify: NotifyService,
    private g: GlobalService,
  private spin: NgxSpinnerService,
    private api: ApiService
  ) {
    env = (window as any).y4wenvs.ENV
    // test for SSR
    if (this.g.isBrowser) {
      try {
        if (env.firebaseConfig) {
          firebase.initializeApp(env.firebaseConfig)
        } else throw Error('Firebase não foi iniciado')
      } catch (e) {
        if (e.message.indexOf('already exists') === -1) console.log('** ERROR', e.message)
      }

      this.storage = firebase.storage()
    }
  }

  getUrl(path: string): Promise<any> {
    const storageRef = this.storage.ref()
    const pathRef = storageRef.child(path)
    return pathRef.getDownloadURL().catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          console.count(error.code)
          break
        case 'storage/unauthorized':
          console.count(error.code)
          break
        case 'storage/canceled':
          console.count(error.code)
          break
        case 'storage/unknown':
          console.count(error.code)
          break
      }
    })
  }


  listAllFiles(path: string): Promise<any> {
    return this.storage.ref().child(path).listAll()
  }

  uploadFiles(files: any[], path, spinner = true, notifications = true): Promise<any[]> {
    if (spinner) this.spin.show()
    return new Promise(async resolve => {
      const filesUrls = []

      for (const file of files) {
        const fileName = `${+ new Date()}_${file.name}`
        const filePath = `${path}/${fileName}`
        const fileRef = this.storage.ref(filePath)
        let uploadedFile = null

        try {
          uploadedFile = await fileRef.put(file, { cacheControl: 'private, max-age=15552000' })
        } catch (e) {
          console.error(e)
          filesUrls.push(null)
        }
        if (uploadedFile) {
          try {
            const downloadURL = await this.getUrl(filePath)
            filesUrls.push({ url: `${downloadURL}`, name: fileName, count: files.length, type: file.type.split('/')[1] })
          } catch (e) {
            console.error(e)
            filesUrls.push(null)
          }
        }
      }
      if (notifications) this.notify.update('files uploaded', 'btn-success', 3000)
      if (spinner) this.spin.hide()
      resolve(filesUrls)
    })
  }

  async uploadXls$$(wbout, nick, seller, wkb) {
    // console.log('** uploadXls$$()');
    return new Promise(function (resolve, reject) {
      const storageRef = this.storage.ref()
      const metadata = { contentType: 'application/vnd.ms-excel' }
      const uploadTask = storageRef.child(`${nick}/${seller}/${wkb}`).put(new Blob([s2ab(wbout)]), metadata)
      uploadTask.on('state_changed', (snapshot) => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // console.log('Upload is ' + progress + '% done')
      }, (err) => {
        // console.log('error', err)
        reject()
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL)
        })
      }
      )
    })
  }

  async uploadBase64(base64: string, name: string, path: string) {
    const ext = base64.replace(/(data:)([\w\/]+)(;base64)(.+)/gm, `$2`)
    const filePath = `${path}/${name}` // .${this.extensions[ext]}`
    const fileRef = this.storage.ref(filePath)
    let uploadedFile: any = null
    try {
      uploadedFile = await fileRef.putString(base64, 'data_url', { cacheControl: 'private, max-age=15552000' })
    } catch (e) {
      console.error(e)
      return null
    }
    if (uploadedFile) {
      try {
        const downloadURL = await this.getUrl(filePath)
        return downloadURL
      } catch (e) {
        console.error(e)
        return null
      }
    }
  }

  resizeImage(base64Image: string, resizeOptions: IResize | IResize[] = null): Promise<string | string[]> {
    if (!this.resizeOptions && !resizeOptions) return Promise.resolve(base64Image)
    return this.api.post('dataImport/resizeImage', {
      base64: base64Image,
      resize: Array.isArray(resizeOptions) ? resizeOptions : [resizeOptions],
      filename: `file-${Date.now()}`
    }, true).pipe(first()).toPromise()
      .then(res => Array.isArray(resizeOptions) ? res : res[0])
  }
}
