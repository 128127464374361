import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { BUILD } from './environments/build'
import { env } from './environments/environment'
import 'hammerjs'
import { PROGRAMDEF } from './environments/program'

if (env.production) {
  enableProdMode()
}

declare let window: any
window['timings'] = window['timings'] || []
window.y4wenvs = window.y4wenvs || {}
window.y4wenvs.PROGRAMDEF = PROGRAMDEF
window.y4wenvs.BUILD = BUILD
window.y4wenvs.ENV = env
window.console.info = (...args) => null

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ref) => {
    window['timings'].push({time: new Date().getTime(), name: 'angular bootstrap finish'})
    // Ensure Angular destroys itself on hot reloads.
    // if (window['ngRef']) {
    //   window['ngRef'].destroy()
    // }
    // window['ngRef'] = ref
    try {
      if ('serviceWorker' in navigator && env.production && !PROGRAMDEF) {
        navigator.serviceWorker.register('/ngsw-worker.js')
      }
    } catch (e) {
    }
  })
// .catch(err => console.log(err));
