import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { FCM } from '@capacitor-community/fcm'
import { Device } from '@capacitor/device'
import { PushNotificationSchema, ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications'
import { StatusBar, Style } from '@capacitor/status-bar'
import { TranslocoService } from '@ngneat/transloco'
import { first } from 'rxjs/operators'
import { NotifyService } from './notifications/notify.service'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import firebase from "firebase/app"
import "firebase/messaging"
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService, replaceStringTags } from './global.service'
import { sleep, use } from './utils'

export interface IPushPayload {
  route: string
  title: string
  subtitle: string
  tokens: string[]
  data: any
}

let env: any

@Injectable({ providedIn: 'root' })
export class PushService {

  private deviceToken: string = null
  private webToken: string = null
  private device: any = null
  private messaging: any = null

  private hasWebInit = false
  private hasNativeInit = false
  private apiUrl = ''
  private fss: FirestoreService2

  constructor(
    private router: Router,
    public g: GlobalService,
    private notify: NotifyService,
    private http: HttpClient,
    private transloco: TranslocoService
  ) {
    env = (window as any).y4wenvs.ENV
    this.apiUrl = this.g.get('apiUrl')
    try {
      Device.getInfo().then(info => {
        if (this.g.user && this.g.user.role === 'admin' && this.g.user.debug) alert(`_Admin debug_\n${info.platform}\n${info.operatingSystem}`)
        this.g.isIos = info.platform.toLowerCase() === 'ios' || info.operatingSystem.toLowerCase() === 'ios'
        this.g.set('capDeviceInfo', info)
      }).catch(e => {
        if (this.g.user && this.g.user.role === 'admin' && this.g.user.debug) alert(`_Admin debug_\n${e.message}`)
      })
    } catch (e) {
      if (this.g.user && this.g.user.role === 'admin' && this.g.user.debug) alert(`_Admin debug_\n${e.message}`)
    }
  }

  startWebPushNotifications() {
    // test for SSR and NAtive
    // tslint:disable-next-line:no-console
    // console.info('# startWebPushNotifications [line:67] => this.g.isNative =', this.g.isNative)
    if (!this.g.isNative) {
      try {
        if (env.firebaseConfig) {
          firebase.initializeApp(env.firebaseConfig)
        } else throw Error('Firebase não foi iniciado')
      } catch (e) {
        if (e.message.indexOf('already exists') === -1) console.info('# startWebPushNotifications [line:74] => catch error =', e.message)
        // console.info('# startWebPushNotifications [line:75] => catch error =', e)
      }

      try {
        this.messaging = firebase.messaging()
        // console.info('# startWebPushNotifications [line:80] => start firebase messaging', this.messaging)

        if (this.messaging !== null) {
          this.messaging.getToken({ vapidKey: env.webPushKey }).then((currentToken) => {
            // console.info('# startWebPushNotifications [line:84] => env.webPushKey =', env.webPushKey)
            this.hasWebInit = true
            if (currentToken) {
              // console.info('# startWebPushNotifications [line:87] => currentToken =', currentToken)
              this.webToken = currentToken
            } else {
              // Show permission request UI
              console.info('# startWebPushNotifications [line:91] => No registration token available. Request permission to generate one.')
              // ...
            }
          }).catch((err) => {
            if (err.message.indexOf('was not granted') === -1) {
              console.info('# startWebPushNotifications [line:96] => An error occurred while retrieving token.')
              console.table(err)
            }
          })

          this.messaging.onMessage((payload: any) => {
            // console.info('# startWebPushNotifications [line:102] => onMessage() =', payload)
            this.pushReceived(payload)
          })
        }
      } catch (e) {
        console.info(e.message)
      }
    }
  }

  startPushNotifications() {
    // test for SSR
    if (this.g.isBrowser && false) {
      if (!this.hasNativeInit) {
        const navBarColor = this.g.program?.colors?.navBg || '#ffffff'

        // window['timings'].push({ time: new Date().getTime(), name: 'startWebPushNotifications()- starting Device.info()' })
        Device.getInfo().then(async info => {
          this.device = info
          window['timings'].push({ time: new Date().getTime(), name: 'startWebPushNotifications()- ended Device.info()' + info.platform })

          if (info.platform === 'android' && navBarColor) {
            await StatusBar.setOverlaysWebView({ overlay: false })
            await StatusBar.setBackgroundColor({ color: navBarColor.value })
            await StatusBar.setStyle({ style: Style.Light })
          }
          let permStatus = null

          try {
            permStatus = await PushNotifications.checkPermissions();
          } catch (e) {
            console.log('# ERR checkPermissions()', e.message)
            return
          }

          if (permStatus.receive === 'prompt') {
            if (info.platform === 'ios' || info.platform === 'android') {
              window['timings'].push({ time: new Date().getTime(), name: 'startWebPushNotifications()- starting requestPermissions()' })
              // Request permission to use push notifications
              // iOS will prompt user and return if they granted permission or not
              // Android will just grant without prompting
              PushNotifications.requestPermissions().then((result: any) => {
                window['timings'].push({ time: new Date().getTime(), name: 'startWebPushNotifications()- ended requestPermissions()' })
                this.hasNativeInit = true
                if (result) {
                  // Register with Apple / Google to receive push via APNS/FCM
                  PushNotifications.register()
                } else {
                  // Show some error
                  // alert('Push not allowed');
                }
              }).catch(err => console.log('# ERR requestPermissions()', err.message || err))

              if (this.device.platform === 'ios') {
                FCM.getToken().then((r) => {
                  // alert(`Token ${r.token}`);
                  // const log = {
                  //   error: {
                  //     message: 'Push Token',
                  //     stack: JSON.stringify(r),
                  //     name: 'Log::Push::Token'
                  //   },
                  //   path: window.location.href,
                  //   user: this.g.get('user') ? this.g.get('user').email : 'indefinido',
                  //   nick: this.g.nick,
                  //   timestamp: new Date().getTime(),
                  //   createdAt: new Date().toISOString(),
                  //   build: BUILD
                  // };

                  this.deviceToken = r.token
                }).catch((err) => console.log('# ERR FCM.getToken()', err.message || err))
              } else {
                PushNotifications.addListener('registration', (token: Token) => {
                  // console.log('Push registration success, token: ' + token.value);
                  // const log = {
                  //   error: {
                  //     message: 'Push Token',
                  //     stack: JSON.stringify(token),
                  //     name: 'Log::Push::Token'
                  //   },
                  //   path: window.location.href,
                  //   user: this.g.get('user') ? this.g.get('user').email : 'indefinido',
                  //   nick: this.g.nick,
                  //   timestamp: new Date().getTime(),
                  //   createdAt: new Date().toISOString(),
                  //   build: BUILD
                  // };

                  this.deviceToken = token.value
                })
              }

              PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error))
              })

              PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => this.pushReceived(notification))
              PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => this.pushReceived(notification, true))

            }
          }
        })
      }
    }
  }

  private pushReceived(notification: any, performed = false) {
    if (!performed) {
      const data: IPushPayload = { ...notification.data } || {}
      if (data.route) {
        this.showConfirm(notification, data)
      } else {
        this.showAlert(notification, data)
      }
    } else {
      const data = { ...notification.notification.data } || {}
      if (data.route) {
        this.showConfirm(notification.notification, data)
      } else {
        this.showAlert(notification.notification, data)
      }
    }
  }

  private async showAlert(notification: any, data: any) {
    this.notify.alert(notification.title || data.title, notification.body || data.subtitle || '').then()
  }

  private showConfirm(notification: any, data: any) {
    this.notify.confirm(notification.title || data.title, notification.body || data.subtitle || '', this.transloco.translate('go_to_route'), this.transloco.translate('cancel'))
      .then(result => result && this.router.navigate([data.route]))
  }

  getUserTokens(user: any): string[] {
    return [...(user.devices.android || []).map(d => d.token), ...(user.devices.ios || []).map(d => d.token), ...(user.devices.web || []).map(d => d.token)]
  }

  sendPush(payload: Partial<IPushPayload>): Observable<any> {
    const push = this.preparePushPayload(payload)
    const api = use<ApiService>(ApiService)
    return api.post('push/sendPush', push).pipe(first())
  }

  private preparePushPayload(payload: Partial<IPushPayload>): any {
    const push = {
      title: payload.title,
      subtitle: payload.subtitle,
      // icon: 'https://firebasestorage.googleapis.com/v0/b/yes-4-web.appspot.com/o/pontonos%2Ficons%2Fandroid-chrome-192x192.png?alt=media&token=35616a6b-5e70-43a0-9284-d780793fa076',
      tokens: payload.tokens,
      payload: {
        title: payload.title,
        subtitle: payload.subtitle,
        ...payload.data,
      },
      webpush: {
        fcm_options: {
          link: `https://${this.g.program.url.replace(/https?:\/\//i, '')}${payload.route ? payload.route : ''}`
          // link: `http://localhost:4500${payload.route ? payload.route : ''}`
        }
      }
    }
    if (payload.route) push.payload.route = payload.route
    return push
  }

  sendManyPushStepByStep(users: any[], payloads: Partial<IPushPayload>): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      for (const u of users) {
        const payload: Partial<IPushPayload> = {
          title: replaceStringTags(u, payloads.title, this.g),
          subtitle: replaceStringTags(u, payloads.subtitle, this.g),
          tokens: [...(u.devices.android || []).map(d => d.token), ...(u.devices.ios || []).map(d => d.token), ...(u.devices.web || []).map(d => d.token)]
        }
        if (payloads.route) payload.route = payloads.route
        this.sendPush(payload).toPromise()
      }
      resolve(true)
    })
  }

  sendManyPush(users: any[], payloads: Partial<IPushPayload>, auth: any): Promise<any> {
    // console.log('# sendManyPush()', users.length);
    console.error('Web workers are not supported in this environment.')
    return this.sendManyPushStepByStep(users, payloads)
    if (typeof Worker !== 'undefined') {
      auth.getUserIdToken().subscribe(token => {
        const program = {
          sender: this.g.program.sender,
          name: this.g.program.name,
          email: this.g.program.email,
          _token: `Bearer ${token}`,
          _url: `${this.apiUrl}/push/sendPush`
        }
        const arr: any[] = []

        for (const u of users) {
          const payload: Partial<IPushPayload> = {
            title: replaceStringTags(u, payloads.title, this.g),
            subtitle: replaceStringTags(u, payloads.subtitle, this.g),
            tokens: [...(u.devices.android || []).map(d => d.token), ...(u.devices.ios || []).map(d => d.token), ...(u.devices.web || []).map(d => d.token)]
          }
          if (payloads.route) payload.route = payloads.route
          if (payload.tokens.length) arr.push({ item: this.preparePushPayload(payload), program })
        }

        if (arr.length > 100) alert('Os PUSH serão enviados em lotes de 100 em segundo plano.\nAcompanhe o progresso no Console\nPodes continuar a navegar no sistema, mas não faça refresh')
        // cria um novo web worker
        const worker: Worker = new Worker(new URL('./workers/push.worker'), { type: 'module' })
        worker.onmessage = ({ data }) => {
          if (data.message) console.log('=>', data.message)
          if (data.action === 'send-many' && data.finished) worker.terminate()
        }
        worker.postMessage({ action: 'send-many', items: arr })
        return Promise.resolve(true)
      })
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.error('Web workers are not supported in this environment.')
      return this.sendManyPushStepByStep(users, payloads)
    }
  }

  async registerDeviceToUser() {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // test for SSR
    if (this.g.isBrowser) {
      // sleep(300) necessario por conta do dispositivo nativo levar um tempo para registar os plugins
      await sleep(300)
      if (this.g.user) {
        if (this.deviceToken) {
          if (this.g.user.devices && this.g.user.devices[this.device.platform]) {
            if (!this.g.user.devices[this.device.platform].find(d => d.token === this.deviceToken)) this.g.user.devices[this.device.platform].push({
              token: this.deviceToken,
              device: `${this.device.manufacturer} ${this.device.model}`,
              createdAt: new Date().toISOString()
            })
          } else {
            this.g.user.devices = this.g.user.devices || {}
            this.g.user.devices[this.device.platform] = this.g.user.devices[this.device.platform] || []
            this.g.user.devices[this.device.platform].push({
              token: this.deviceToken,
              device: `${this.device.manufacturer} ${this.device.model}`,
              createdAt: new Date().toISOString()
            })
          }
        }

        if (this.webToken) {
          // console.log('# webToken')
          if (this.g.user.devices && this.g.user.devices['web']) {
            if (!this.g.user.devices['web'].find(d => d.token === this.webToken)) this.g.user.devices['web'].push({
              token: this.webToken,
              device: `browser`,
              createdAt: new Date().toISOString()
            })
          } else {
            this.g.user.devices = this.g.user.devices || {}
            this.g.user.devices['web'] = this.g.user.devices['web'] || []
            this.g.user.devices['web'].push({
              token: this.webToken,
              device: `browser`,
              createdAt: new Date().toISOString()
            })
          }
        }

        // regista o tipo de device de user para futuros filtros no backoffice
        if (this.g.get('capDeviceInfo')) {
          const info = this.g.get('capDeviceInfo')
          this.g.user.connectedDevices = this.g.user.connectedDevices || []
          this.g.user.platforms = this.g.user.platforms || []
          const device = `${info.manufacturer}_${info.model}_${info.operatingSystem}_${info.osVersion}_${info.platform}`
          if (!this.g.user.connectedDevices.filter(p => p.startsWith(device)).length) this.g.user.connectedDevices.push(`${device}_${new Date().toISOString()}`)
          if (this.g.user.platforms.indexOf(info.platform.toLowerCase()) === -1) this.g.user.platforms.push(info.platform.toLowerCase())
        }

        if (this.g.user.devices || this.g.user.connectedDevices || this.g.user.platforms) this.fss.merge(`users`, this.g.user.id || this.g.user.uid, {
          createdAt: this.g.user.createdAt,
          createdBy: this.g.user.createdBy,
          devices: this.g.user.devices || {},
          connectedDevices: this.g.user.connectedDevices || [],
          platforms: this.g.user.platforms || []
        }, { silent: true })
      } else console.log('## this.g.user undefined')
    }
  }
}
