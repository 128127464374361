import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { EventService } from './event.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { timeOut, use } from './utils'

export enum AutomationStatus {
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  RECURRENT = 'RECURRENT',
  PERMANENT = 'PERMANENT',
  FRONTEND = 'FRONTEND'
}

export interface IAutomation {
  active: boolean
  conditions: string[] // array com os nomes das funções Conditions no backend
  description: string
  event?: string
  hour?: number
  minute?: number
  name: string
  options: any[] // array com objectos a ser passado para as Conditions e Workes
  // performAt?: { nanoseconds: number; seconds: number }; // padrão timestamp server do firebase
  performAt?: string // padrão timestamp server do firebase
  refId?: string // id do objecto que geraou este automatismo
  collection?: string // collection onde está o objecto que gerou o automatismo
  status: AutomationStatus
  updatedAt?: string
  updatedBy?: string
  weekDay?: number
  workers: string[] // array com os nomes das funções Workers no backend
}

@Injectable({
  providedIn: 'root'
})
export class AutomationsService implements OnDestroy {

  private subs = new SubSink()
  private automationsFrontend: any[] = []

  constructor(
    private fss: FirestoreService2,
    private g: GlobalService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document) {
    console.info(`## ${this.constructor.name}`)
    this.subs.add(
      this.fss.list('automations', { where: [['status', '==', AutomationStatus.FRONTEND], ['active', '==', false]] }).subscribe(automations => this.automationsFrontend = automations)
    )
    this.listenEvents()
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  /**
   * Devolve os TIPOS (status) de automatismos disponíveis
   * @returns {(AutomationStatus.RECURRENT | AutomationStatus.PERMANENT)[]}
   */
  get automationTypes() {
    return [AutomationStatus.RECURRENT, AutomationStatus.PERMANENT]
  }

  /**
   * Devolve um Array de strings com os nomes dos eventos disponíveis no backend
   * @returns {string[]}
   */
  get events() {
    return [
      'first_login',
      'new_register',
      'new_prospect',
      'new_post',
      'new_challenge',
      'answer_challenge'
    ]
  }

  /**
   * Devolve um Array de strings com os nomes dos eventos disponíveis no frontend
   * @returns {string[]}
   */
  get frontendEvents() {
    return [
      'on_login',
      'first_login',
      'enter_app',
      'new_register',
      'new_post'
    ]
  }

  /**
   * Devolve um Array de strings com os nomes das funções Workers disponíveis no backend
   * @returns {string[]}
   */
  get workers() {
    return [
      'sendEmailToUsers',
      'sendSmsToUsers',
      'sendPushToUsers',
      // 'applyPointsToUser',
      // 'applyPointsToSegment'
      'executeOneFunction',
      'executeScript',
      'callUrl'
    ]
  }

  /**
   * Devolve um Array de strings com os nomes das funções Workers disponíveis no backend
   * @returns {string[]}
   */
  get frontendWorkers() {
    return [
      // 'openModal',
      'navigateTo',
      'executeScript'
    ]
  }

  /**
   * Devolve um Array de strings com os nomes das funções Conditions disponíveis no backend
   * @returns {string[]}
   */
  get conditions() {
    return [
      'event_occurred',
      'all_users_logged_in',
      'all_users_not_logged_in',
      'all_users_segment_logged_in',
      'all_users_segment_not_logged_in',
      'all_active_users',
      'all_users',
      'all_influencers',
      'by_user_id',
      'by_segment_id',
      'by_object_id',
      'all_users_with_challenge_not_completed',
      'all_users_with_anonymous_survey_not_completed',
      'all_influencers_with_challenge_not_completed'
    ]
  }

  /**
   * Devolve a lista de parâmetros de uma função Worker
   * @param {string} worker
   * @returns {any}
   */
  getWorkersParams(worker: string) {
    const params = {
      sendEmailToUsers: ['templateId', 'text', 'subject'],
      sendSmsToUsers: ['text'],
      sendPushToUsers: ['title', 'text'],
      executeOneFunction: ['functionName'],
      executeScript: ['script'],
      navigateTo: ['path'],
    }
    return params[worker] || []
  }

  /**
   * Devolve a lista de parâmetros de uma função Conditions
   * @param {string} condition
   * @returns {any}
   */
  getConditionsParams(condition: string) {
    const params = {
      all_users_segment_logged_in: ['segment'],
      all_users_segment_not_logged_in: ['segment'],
      by_user_id: ['id'],
      by_segment_id: ['id'],
      by_object_id: ['id', 'collection'],
      all_users_with_challenge_not_completed: ['challengeId'],
      all_users_with_anonymous_survey_not_completed: ['challengeId'],
      all_influencers_with_challenge_not_completed: ['challengeId'],
    }
    return params[condition] || []
  }

  /**
   * Lista os automatismos RECURRENT e PERMAMENT registados para o nick atual
   * Por defeito, lista apenas os automatismos ativos
   * Passar FALSE como parâmetro, retorna a lista com ativos e inativos
   * @param {boolean} onlyActives
   * @returns {Observable<any[]>}
   */
  listAutomations(onlyActives = true): Observable<any[]> {
    const where: any[] = [['status', 'in', [AutomationStatus.RECURRENT, AutomationStatus.PERMANENT, AutomationStatus.FRONTEND]]]
    if (onlyActives) where.push(['active', '==', true])
    return this.fss.list('automations', { where })
      .pipe(map((values: any[]) => values.map(v => ({ ...v, _actions: (v.workers || []).length }))))
  }

  /**
   * Lista os automatismos para uma referencia especifica
   * @param {boolean} onlyActives
   * @returns {Observable<any[]>}
   */
  listAutomationsByRef(refId: string, collection: string): Observable<any[]> {
    return this.fss.list('automations', { where: [['refId', '==', refId], ['collection', '==', collection]] })
      .pipe(map((values: any[]) => values
        .filter(v => v.type !== 'bulk')
        .map(v => ({ ...v, _sendAt: v.performAt/*this.fbTimestampToDate(v.performAt).toISOString()*/, _actions: (v.workers || []).length }))
        .sort((a, b) => a._sendAt === b._sendAt ? 0 : a._sendAt < b._sendAt ? -1 : 1)))
  }

  /**
   * Grava um automatismo
   * Se um ID for fornecido, o automatismos será criado com este ID ou atualizado caso exista
   * @param {IAutomation} automation
   * @param {string} id
   * @returns {Promise<any>}
   */
  registerAutomation(automation: Partial<IAutomation>, id: string = null): Promise<any> {
    return this.fss.merge('automations', id || this.fss.createId, automation)
  }

  /**
   * Dispacha um evento de automatismos que será capturado pelo backend
   * @param {string} event
   * @param data
   * @param delay
   * @returns {Observable<any>}
   */
  dispatchEvent(event: string, data?: any, delay = 0) {
    timeOut(() => {
      EventService.get(event).emit(data)
      const api = use<ApiService>(ApiService)
      this.subs.add(
        api.post('automations/registerEvent?noError=1', { event, data: data || null }).subscribe(res => null)
      )
    }, delay)
  }

  onEvent(event: string): Observable<any> {
    return EventService.get(event)
  }

  /**
   * Converte uma data JavaScript por um Firebase Server Timestamp
   * @param {Date} date
   * @returns {{seconds: number, nanoseconds: number}}
   */
  dateToFBTimestamp(date: Date) {
    return {
      nanoseconds: 0,
      seconds: date.getTime() / 1000
    }
  }

  /**
   * Converte o fb.server.timestamp para um objecto JavaScript Date
   * @param timestamp
   * @returns {Date}
   */
  fbTimestampToDate(timestamp: any) {
    return new Date(timestamp.seconds * 1000)
  }

  runAutomation() {
    const api = use<ApiService>(ApiService)
    api.post('automations/runAutomation', {}).subscribe(() => console.log('Automation run OK'))
  }

  import() {
    const api = use<ApiService>(ApiService)
    api.post('automations/import', {}).subscribe(console.log)
  }

  private listenEvents() {
    for (const evt of this.frontendEvents) {
      this.subs.add(EventService.get(evt).subscribe(e => {
        for (const aut of this.automationsFrontend) {
          if (aut.event === evt) {
            for (const [i, work] of aut.workers.entries()) {
              switch (work) {
                case 'navigateTo':
                  this.g.set('fullscreen', true)
                  this.router.navigate([aut.options[i].path])
                  break
                case 'executeScript':
                  // tslint:disable-next-line:no-eval
                  eval(aut.options[i].script)
              }
            }
          }
        }
      }))
    }
  }
}
