import { Injectable } from '@angular/core'
import { first, forkJoin } from 'rxjs'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { clearFieldsBlank, objectToArray, use } from './utils'
import slugify from 'slugify'

@Injectable({ providedIn: 'root' })
export class LangsService {

  private fss: FirestoreService2

  constructor(
    private g: GlobalService
  ) { }

  getLocales() {
    return [
      {code: 'pt-PT', name: 'Português'},
      {code: 'en-GB', name: 'Inglês'},
      {code: 'es-ES', name: 'Espanhol'},
      // {code: 'fr-FR', name: 'Francês'}
    ]
  }

  list(locale: string): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return new Promise<any>(resolve => {
      const baseTerms$ = this.fss.get('langs', 'terms', { api: true }) // .pipe(first())
      const baseLang$ = this.fss.get('langs', locale, { api: true }) // .pipe(first())
      const pgmLang$ = this.fss.get('langs', `${locale.split('-')[0]}-${this.g.nick}`, { api: true }) // .pipe(first())
      forkJoin([baseLang$, pgmLang$, baseTerms$]).subscribe(results => {
        if (!results[1]) {
          // results[1] = results[0]
          results[1] = { _lang: `${locale.split('-')[0]}-${this.g.nick}` }
          this.fss.set('langs', `${locale.split('-')[0]}-${this.g.nick}`, results[1])
        }
        const map = new Map()
        objectToArray(results[0], 'id', 'base').forEach(item => map.set(item.id, item))
        objectToArray(results[1], 'id', 'program').forEach(item => map.set(item.id, { ...map.get(item.id), ...item }))
        // console.log(Array.from(map.values()).sort((a, b) => a.id.toUpperCase() === b.id.toUpperCase() ? 0 : a.id.toUpperCase() < b.id.toUpperCase() ? -1 : 1));
        resolve(Array.from(map.values()).sort((a, b) => a.id.toUpperCase() === b.id.toUpperCase() ? 0 : a.id.toUpperCase() < b.id.toUpperCase() ? -1 : 1))
      })
    })
  }

  listTerms(): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.list('languages', { take: 1 }).pipe(first()).toPromise()
  }

  saveTerm(term: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    return this.fss.set('languages', term.id || slugify(term.term, { replacement: '_', trim: true, lower: true, strict: true }), clearFieldsBlank(term))
  }

  save(result, data, locale) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const save = { base: {}, program: {}}
    for (const d of data) {
      save.base[d.code] = d.base
      save.program[d.code] = d.program
    }
    save.base[result.id] = result.base
    save.program[result.id] = result.program
    // console.log('langs', 'pt-PT', save.base)
    this.fss.update('langs', locale, save.base)
    // console.log('langs', `pt-${this.g.nick}`, save.program)
    this.fss.update('langs', `${locale.split('-')[0]}-${this.g.nick}`, save.program)
  }

  clone(nick?) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    this.fss.get('langs', 'pt-PT', { api: true }).toPromise().then(lang => {
      // console.log('langs', 'pt-' + this.g.nick, lang)
      this.fss.set('langs', 'pt-' + this.g.nick, lang)
      // TODO MRS What happens if we are creating a new language but we don't want it to be the default language for the current
      //  logged in program? Here the program will always be updated
      const _nick = nick || this.g.nick
      // this.fss.update('programs', this.g.nick, { defaultLang: 'pt-' + _nick })
    })
  }

}
