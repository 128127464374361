import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { GlobalService } from './global.service'

@Injectable({
  providedIn: 'any'
})
export class UserResolver implements Resolve<Observable<any>> {

  constructor(
    private router: Router,
    private auth: AuthService,
    public g: GlobalService
  ) {
    console.info(`## ${this.constructor.name}`)
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.auth.user.pipe(
      take(1),
      tap(user => {
        // console.log(user)
        // if (!this.g.get('user')) {
        //   this.g.set('user', user)
        // }
        this.g.set('user', user)
      })
    )
  }
}
