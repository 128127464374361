<link rel="preconnect" href="https://fonts.gstatic.com">
<ng-container *ngIf="!networkBlock else blocked">
  <div class="{{g.nick}} block-screen" *ngIf="!isLocal && router.url.indexOf('login/token') === -1 && g.program?.disclaimer && g.program?.stage !== 'production' else unblocked"
    [ngStyle]="{backgroundImage: 'url('+bg+')'}">
    <div class="overlay">
      <div class="disclaimer" [innerHTML]="g.program?.disclaimer[g.program?.stage] | safeHtml"></div>
    </div>
  </div>

  <ng-template #unblocked>
    <router-outlet />
  </ng-template>
</ng-container>

<ng-template #blocked>
  <div class="block-screen fcc">
    <img [src]="g.program.images.logoMobile || g.program.images.logo" alt="">
    <h2>{{g.program.msgs.whitelist}}</h2>
  </div>
</ng-template>
