import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { GlobalService } from '../global.service'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[checkRole]'
})
export class CheckRoleDirective {

  private show = false

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private g: GlobalService) {
  }

  @Input('checkRole') set roles(roles: string) {
    const theRoles = roles.split(',')
    if ((this.g.user && this.g.user.role && theRoles.indexOf(this.g.user.role) > -1)) {
      if (!this.show) {
        this.viewContainer.createEmbeddedView(this.templateRef)
        this.show = true
      }
    } else {
      this.viewContainer.clear()
      this.show = false
    }
  }

}
