import { RankingService } from './ranking.service'
import { Injectable } from '@angular/core'
import { Observable } from "rxjs"
import { first, switchMap } from 'rxjs/operators'
import { ApiService } from './api.service'
import { AuthService } from './auth.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService, replaceStringTags } from './global.service'
import { Base64, use } from './utils'
import { HttpClient } from '@angular/common/http'

@Injectable({ providedIn: 'root' })
export class EmailService {
  type: string
  nick: string
  apiUrl: string
  count: number
  program: any
  private fss: FirestoreService2

  constructor(
    public g: GlobalService,
    private http: HttpClient
  ) {
    console.info(`## ${this.constructor.name}`)
    this.apiUrl = this.g.get('apiUrl')
  }

  emailProgram() {
    return {
      name: this.g.program.name,
      url: this.g.program.url,
      sender: this.g.program.sender,
      email: this.g.program.email,
      imgHeader: this.g.program.images.emailHeader,
      imgFooter: this.g.program.images.emailFooter,
      xrate: this.g.program.xrate,
      subject: '',
      templateId: '99',
      sendWelcomeOnImport: this.g.program.emails.sendWelcomeOnImport || false,
      sendEmailOnApplyPoints: this.g.program.emails.sendEmailOnApplyPoints || false,
    }
  }

  sendMany(items: any[], program: any) {
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`sendinblue/sendMany`, { items: items, program: program }, true)
  }

  sendOne(item: any, program: any): Observable<any> {
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`sendinblue/sendOne`, { item: item, program: program }, true)
  }

  sendPlain(mail: any, program: any): Observable<any> {
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`open/___send_plain__`, { mail, program }, true)
  }

  /**
   * Envia UM email baseado nos templates do sistema (editor)
   * OBS: Este método não comporta envio de email com PARASM (parametros extras)
   * @param user Objecto do utilizador que receberá o email
   * @param template Objecto que representa o template
   */
  sendOneTemplate(user: any, template: any, html = null): Observable<any> {
    const program = {
      sender: this.g.program.sender,
      name: this.g.program.name,
      email: this.g.program.email,
      subject: template.subject,
      html: ''
    }
    const plainHtml = html || JSON.parse(Base64.decode(template.editor)).html

    if (!html && template.ranks && template.ranks.length) {
      const rankSrv: RankingService = use<RankingService>(RankingService)
      return rankSrv.generateRankEmailRows(template.ranks, plainHtml)
        .pipe(switchMap(html => this.sendOne(user, { ...program, html: this.replaceEmailTags(user, html) })))
    } else return this.sendOne(user, { ...program, html: this.replaceEmailTags(user, plainHtml) })
  }

  /**
   * Envia Vários emails baseado nos templates do sistema (editor)
   * OBS: Este método não comporta envio de email com PARAMS (parametros extras)
   * @param users Array com objectos de utilizadores que receberão os emails
   * @param template Objecto que representa o template
   */
  private sendManyTemplate(users: any[], template: any, auth: any, html = null): Promise<any> {
    return new Promise<any>(async resolve => {
      if (typeof Worker !== 'undefined') {
        auth.getUserIdToken().subscribe(token => {
          const program = {
            sender: this.g.program.sender,
            name: this.g.program.name,
            email: this.g.program.email,
            subject: template.subject,
            html: '',
            _token: `Bearer ${token}`,
            _url: `${this.apiUrl}/sendinblue/sendOne`
          }
          const plainHtml = html || JSON.parse(Base64.decode(template.editor)).html
          const arr: any[] = []

          for (const oneUser of users) {
            // program.html = this.replaceEmailTags(oneUser, plainHtml);
            arr.push({ item: oneUser, program: { ...program, html: this.replaceEmailTags(oneUser, plainHtml) } })
          }

          if (arr.length > 100) alert('Os emails serão enviados em lotes de 100 em segundo plano.\nAcompanhe o progresso no Console\nPodes continuar a navegar no sistema, mas não faça refresh e não feche o navegador')
          // cria um novo web worker
          this.http.get('workers/email.worker.ts', { responseType: 'blob' }).subscribe(blob => {
            const worker = new Worker(URL.createObjectURL(blob));
            worker.onmessage = ({ data }) => {
              if (data.message) console.log('=>', data.message)
              if (data.action === 'send-many' && data.finished) worker.terminate()
            }
            worker.postMessage({ action: 'send-many', items: arr })
          });
          // const worker: Worker = new Worker('./workers/email.worker.ts')
          resolve(true)
        })
      } else {
        // Web workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
        console.error('Web workers are not supported in this environment.')
        for (const item of users.map(({ displayName, firstName, balance, email, segment }) => ({ displayName, firstName, balance, email, segment }))) {
          await this.sendOneTemplate(item, template).pipe(first()).toPromise()
        }
        resolve(true)
      }
    })

  }

  sendCampaignTemplate(users: any[], template: any) {
    return new Promise<any>((resolve, reject) => {
      const auth = use<AuthService>(AuthService)
      if (template.ranks && template.ranks.length) {
        const rankSrv: RankingService = use<RankingService>(RankingService)
        rankSrv.generateRankEmailRows(template.ranks, JSON.parse(Base64.decode(template.editor)).html).subscribe(html => {
          this.sendManyTemplate(users, template, auth, html).then(ok => resolve(ok)).catch(er => reject(er))
        })
      } else {
        this.sendManyTemplate(users, template, auth).then(ok => resolve(ok)).catch(er => reject(er))
      }

    })
  }

  sendConfirmationCardLoadEmail(card, user: any = null): Observable<any> {
    const _program = {
      name: this.g.program.name,
      sender: this.g.program.sender,
      email: this.g.program.email,
      url: this.g.program.url,
      subject: `Registo do carregamento do ${card.name} nº ${card.cardId}`,
      html: `O carregamento do ${card.name} nº ${card.cardId} ficou registado na plataforma ${this.g.program.name} e no prazo de 10 dias úteis o carregamento será efectuado.<br><br>
      <b>Muito importante:</b>
      <br>Por favor confirma o nº série do cartão e caso este não coincida com o cartão que tens em teu poder, liga-nos para o  21 406 76 94<br><br>
      Caso tenhas alguma dúvida, contacta o <b>serviço de apoio</b>.<br>
      (Disponível de segunda a sexta das 9h30 às 13:00 e das 14:00 às 18h00)<br>`,
      imgHeader: this.g.program.images['emailHeader'],
      imgFooter: this.g.program.images['emailFooter'],
      templateId: this.g.program.emails['generic']
    }

    const _item = {
      displayName: user.displayName,
      email: user.email,
    }
    const api: ApiService = use<ApiService>(ApiService)
    return api.post('sendinblue/sendOne', { item: _item, program: _program }, true)
  }

  /**
   * Envia um email de confirmação de encomenda
   * Este método pode enviar este email usando template do Sendingblue ou templates do sistema (editor)
   * @param order
   */
  async sendConfirmationOrderEmail(order: any): Promise<any> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // se o templateId não for um Numero, então é um ID de template interno (editor)
    // caso seja um número, é um template do Sendingblue
    if (isNaN(order.program.templateId)) {
      return this.fss.get('templates', order.program.templateId).pipe(first()).toPromise().then(template => {
        const item = {
          name: order.displayName,
          firstName: order.displayName.split(' ')[0],
          email: order.email,
        }
        const params = {
          'id': order.id,
          'program_url': order.program.url,
          'name': order.displayName,
          'address1': order.deliveryTo.address1,
          'address2': order.deliveryTo.address2,
          'postcode': order.deliveryTo.postCode,
          'city': order.deliveryTo.city,
          'order_date': order.orderDate.substring(0, 10),
          'order_qty': order.orderQty,
          'order_amt': order.orderPoints,
          'delivery_date': order.deliveryDate,
          'contactus': `${order.program.url}/rewards/events/contactus/detail/${this.g.user.username}`,
          'cart': Array.from(order.cart),
        }
        const program = {
          sender: this.g.program.sender,
          name: this.g.program.name,
          email: this.g.program.email,
          subject: order.subject,
          html: this.replaceEmailTags(item, JSON.parse(Base64.decode(template.editor)).html.replace(/\n/g, ''), params)
        }
        //
        return this.sendOne(item, program).toPromise()
      })
    } else {
      // envia com template do sendingblue
      const api: ApiService = use<ApiService>(ApiService)
      return api.post(`sendinblue/sendOrderConfirmation`, order, true).pipe(first()).toPromise()
    }
  }

  sendOrderEmailToSeller(order: any): Promise<any> {
    order.program.templateId = this.g.program.emails['sellerEmail']
    order.subject = 'Pedido de prémio '
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`sendinblue/sendOrderConfirmation`, order, true).pipe(first()).toPromise()
  }

  // TODO WG alterar para template do sistema
  sendPaymentOk(order: any): Observable<any> {
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`sendinblue/sendPaymentOk`, order, true)
  }

  // TODO WG alterar para template do sistema
  sendPaymentPending(order: any): Observable<any> {
    const api: ApiService = use<ApiService>(ApiService)
    return api.post(`sendinblue/sendPaymentPending`, order, true)
  }

  /**
   * Substitui as TAGS especiais no template pelos dados do utilizador
   * @param user O user a ser preenchido
   * @param template O template a ser substituído
   */
  replaceEmailTags(user: any, template: string, params: any = null): string {
    return replaceStringTags(user, template, this.g, params)
  }

}
