export enum PrimaryCode {
  ORDER = 'Encomenda',
  POINTS = 'Pontos',
  MESSAGE = 'Mensagem',
  PAYMENTS = 'Pagamentos'
}

export enum TransactionCode {
  ADD_POINTS = 'Pontos atribuidos',
  ORDER = 'Encomenda',
}

export enum OrderStatus {
  PLACED = 'PLACED', // Colocada
  CANCELED = 'CANCELED', // Cancelada
  ORDERED = 'ORDERED', // Enc.Fornecedor
  ALL_ORDERED = 'ALL_ORDERED', // Enc.Fornecedor(total)
  PARTIAL_ORDERED = 'PARTIAL_ORDERED', // Enc.Fornecedor(parcial)
  COMMISSIONED = 'COMMISSIONED', // <- deprecated ?
  INVOICED = 'INVOICED', // Expedida
  PARTIAL_INVOICED = 'PARTIAL_INVOICED', // Expedida(parcial)
  PAYMENT_PENDING = 'PAYMENT_PENDING', // Expedida(total)
  READY = 'READY', // Preparada
  DELIVERED = 'DELIVERED', // Entregue
}

export enum MessageStatus {
  PENDING = 'Pendente',
  SOLVED = 'Resolvido',
}

export enum ScheduleStatus {
  OPEN = 'OPEN',
  SCHEDULED = 'SCHEDULED',
  ABSENT = 'ABSENT',
  REQUIRED = 'REQUIRED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}