import { NgModule } from '@angular/core'
import { NoPreloading, RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },

  // o modulo de login é carregado lazy fira do modulo main, desta forma temos o promeiro load apenas
  // do bootstrap do Angular e do ecrã de login, só passando para o main em lay, após estar autenticado
  { path: 'login', loadChildren: () => import('@user/user.module').then(m => m.UserModule) },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: NoPreloading,
    // relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 80]
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
