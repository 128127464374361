import { first } from 'rxjs/operators'
import { Cry } from './cry.service'
import { StyleLoader } from './style.loader'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GlobalService } from './global.service'
declare var window: any
let PROGRAMDEF: any
let env: any

@Injectable()
export class AppInitService {

  constructor(
    private httpClient: HttpClient,
    private g: GlobalService,
    private styleLoader: StyleLoader
  ) {
    env = (window as any).y4wenvs.ENV
    PROGRAMDEF = (window as any).y4wenvs.PROGRAMDEF
    this.getGlobals()
    console.info(`## ${this.constructor.name}`)
  }

  private getGlobals() {
    let host = window.location.href || null
    const boNick = Cry.getLocal('bo-nick', false)
    host = host !== null ? host.replace('https://', '').replace('http://', '') : null

    let nick = host.split('.')[0] || 'yesmkt'
    if (document && nick.indexOf('localhost') > -1 || nick === '192') nick = (document as any).body.dataset.nick
    if (nick === 'backoffice') nick = 'admin'
    if (boNick) nick = boNick

    // projectID vem agora do HTML, gerado no build
    const projectId = document.body.dataset.projectId || 'yes4eutests' // 'yes4eu'
    window.projectId = projectId
    const url = 'https://firestore.googleapis.com/v1/projects'
    Cry.set('url', url, true)
    Cry.set('projectId', projectId, true)
    Cry.set('nick', nick, true)
    Cry.set('isLocal', window.location.port === "4600")
    Cry.set('native', false)
    return { url, projectId, nick }
  }

  init(): Promise<any> {
    const dt = new Date
    // ### necessario para o app nativo
    this.loadNativeGlobals()

    const nick = Cry.get('nick', true)
    const type = Cry.get('type', true)
    const projectId = Cry.get('projectId', true)
    const region = env.region || 'europe-west3'
    const program = Cry.get('program', true)
    const port = ((window as any).location.port)
    let apiUrl = `https://${region}-${projectId}.cloudfunctions.net`
    const localApi = localStorage.getItem('localApi') ? JSON.parse(localStorage.getItem('localApi')) : true
    if (['4500', '4600', '4700'].includes(port) && localApi) {
      apiUrl = `http://localhost:5001/${projectId}/${region}`
    }
    // apiUrl = `http://localhost:5001/${projectId}/${region}`
    this.g.set('apiUrl', Cry.get('apiUrl') || apiUrl)

    this.httpClient.post(`${apiUrl}/open/d/action`, Cry.crypt(JSON.stringify({ action: 'menus', nick: 'yes' }), true)).pipe(first()).toPromise().then(_menus => {
      const menus = JSON.parse(Cry.decrypt(_menus as string, true))
      Cry.set('menus', menus, true)
    })

    if (Cry.get('pt-PT', false)) this.g.set('pt-PT', Cry.get('pt-PT', false))
    if (Cry.get(`pt-${nick}`, false)) this.g.set(`pt-${nick}`, Cry.get(`pt-${nick}`, false))

    if (Cry.get('program')) {
      window['timings'].push({ time: new Date().getTime(), name: 'app.init started' })
      this.g.set('program', program)
      this.g.set('type', type)
      this.g.set('nick', program.nick)
      this.g.set('access', Cry.get('access'))
      if (Cry.get('isLocal')) this.styleLoader.loadStyle(nick) //  || Cry.get('native')
      else this.styleLoader.loadStyle(nick, program.cssUrl)
    } else {
      const url = Cry.get('url')
      window['timings'].push({ time: new Date().getTime(), name: 'app.init fetching program' })
      const promise = this.httpClient.post(`${apiUrl}/open/d/action`, Cry.crypt(JSON.stringify({ action: 'program', nick }), true)).pipe(first())
        .toPromise()
        .then((res: string) => {
          window['timings'].push({ time: new Date().getTime(), name: 'app.init fetched program' })
          const _program = JSON.parse(Cry.decrypt(res, true))
          if (_program && _program.name) {
            // let dt = new Date;
            // console.log(dt.toISOString(), '## app.init program fetched ', _program.name)
            window['timings'].push({ time: new Date().getTime(), name: 'app.init program fetched' })
            this.g.set('program', _program); Cry.set('program', _program, true)
            this.g.set('type', _program.type); Cry.set('type', _program.type)
            this.g.set('access', _program.access); Cry.set('access', _program.access)
            this.g.set('nick', _program.nick)
            if (_program.campaigns) {
              this.g.set('campaigns', _program.campaigns)
              this.g.set('campaign', _program.campaigns[nick])
            }
            if (Cry.get('isLocal')) this.styleLoader.loadStyle(_program.nick || Cry.get('nick'))
            else this.styleLoader.loadStyle(_program.nick || Cry.get('nick'), (_program || Cry.get('program', true))['cssUrl'])
          } // else console.log('# app.init => program não existe');
        }).catch(err => {
          console.log('# ERROR', err.message)
        })
      return promise
    }
    // return Promise.resolve(true)
  }

  /**
   * Método que carrega os dados pre-definidos para um app nativo
   * @private
   */
  private loadNativeGlobals() {
    const progdef = PROGRAMDEF || null
    // console.log('# native globals', progdef/* || { isLocal, nick, projectId, apiUrl, projectName: projectId, native: false }*/);
    if (progdef) {
      Cry.set('isLocal', progdef.isLocal)
      Cry.set('native', progdef.native)
      Cry.set('nick', progdef.nick)
      Cry.set('projectId', progdef.projectId)
      Cry.set('projectName', progdef.projectName)
      Cry.set('apiUrl', progdef.apiUrl)

      this.g.set('native', progdef.native)
      this.g.set('isLocal', progdef.isLocal)
    }
  }

}
