import { Injectable } from '@angular/core'
import { NotifyService } from './notifications/notify.service'
import { ApiService } from './api.service'
import { EmailService } from './email.service'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })
export class ProspectsService {
  type: string = null
  apiUrl: string = null
  count: number = null

  constructor(
    public g: GlobalService,
  ) {
    console.info(`## ${this.constructor.name}`)
    this.apiUrl = this.g.get('apiUrl')
  }

  public sendWelcomeProspects(prospects) {
    const emailServ = use<EmailService>(EmailService)
    const api = use<ApiService>(ApiService)
    const notify = use<NotifyService>(NotifyService)
    const emailProgram = emailServ.emailProgram()
    emailProgram.templateId = this.g.program.emails.welcomeProspect
    emailProgram.subject = this.g.get('program').name + ' | Ativa a tua conta'
    api.post(`sendinblue/sendWelcomeProspects`, { items: prospects, program: emailProgram }, true).toPromise().then(res => {
      if (res) notify.update('Emails de boas-vindas enviado', 'btn-success', 3000)
      // console.log(res)
    })
  }
}
