import { GlobalService } from './global.service'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { NotifyService } from './notifications/notify.service'

@Injectable({ providedIn: 'root' })
export class InfobipService {
  nick: string
  program: any
  apiUrl: any

  constructor(
    private http: HttpClient,
    private notify: NotifyService,
    public g: GlobalService
  ) {
    console.info(`## ${this.constructor.name}`)
    this.apiUrl = this.g.get('apiUrl')
  }

  sendSMS(to, msg) {
    return new Promise((resolve, reject) => {
      // const endpoint = `${env.program[this.g.nick].api}infobip/sendSMS`
      const endpoint = this.apiUrl + '/infobip/sendSMS'
      let from = this.g.nick
      from = 'NOS F e A'
      this.http.post(endpoint, { from: from, to: JSON.stringify(to), msg: msg })
        .toPromise()
        .then((result) => {
          // console.log(result);
          resolve(result)
        })
        .catch((error: any) => {
          this.sendErrMsg(error)
          reject(error)
        })
    })
  }

  sendErrMsg(error, collectionObj?) {
    if (error.code === 'permission-denied') this.notify.update('Não tem autorização para realizar esta operação: ' + collectionObj, 'btn-danger', 3000)
  }
}
