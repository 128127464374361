import { NgxSpinnerService } from 'ngx-spinner'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { first } from 'rxjs/operators'
import { ApiService } from './api.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { use } from './utils'

@Injectable({ providedIn: 'root' })
export class FnacService {

  private fss: FirestoreService2

  constructor(
    private http: HttpClient,
    private spin: NgxSpinnerService,
    public g: GlobalService,
  ) {  }

  updateProduct(product: any = null): Promise<any> {
    return Promise.resolve(product)
    // TODO: Reativar quando a API fnac voltar a funcionar
    /*if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    let pid = product?.sellerCode
    if (/^(\D{1})(\d{4,15})/.test(product?.sellerCode)) pid = (product?.sellerCode).match(/(\D{1})(\d{4,15})/)[2]
    else if (/^\d+/.test(product?.sellerCode) === false) pid = null

    const now = new Date().getTime()
    if ((product.seller === 'FNAC' || product.seller === 'ODISSEIAS') && (!product.updatedTimestamp || (now - product.updatedTimestamp) > 86400000)) {
      if (pid) {
        const api = use<ApiService>(ApiService)
        return api.get(`fnac/getProductById?productId=${pid}`).pipe(first()).toPromise().then(async fnacProd => {
          if (product.name.toLowerCase() === fnacProd.name.toLowerCase()) {
            const prod = { ...product, ...fnacProd }
            prod['updatedBy'] = 'FNAC'
            prod['updatedAt'] = new Date().toISOString()
            prod['updatedTimestamp'] = new Date().getTime()
            prod['deliveryPeriod'] = 15
            prod['points'] = Math.round((parseFloat(prod['price']) / this.g.program.cbXrate + .49) / this.g.program.xrate)
            prod['active'] = prod.stock && +prod.price > 0
            await this.fss.update('products', product.sellerCode, prod, { silent: true })
            return prod
          } else {
            await this.fss.update('products', product.sellerCode, { active: false, stock: false, createdAt: product.cretaedAt }, { silent: true })
            return product
          }
        })
      } else return Promise.resolve(product)
    } else return Promise.resolve(product)*/
  }

  batchProductUpdate(products: any[]): Promise<boolean> {
    return Promise.resolve(false)
    // TODO: Reativar quando a API fnac voltar a funcionar
    /*return new Promise(resolve => {
      const now = new Date().getTime()
      if (this.g.program.options.fnac && products && products instanceof Array && products.filter(p => p.seller === 'FNAC' || p.seller === 'ODISSEIAS').length) {
        const updatables = products.filter(p => !p.updatedTimestamp || now - p.updatedTimestamp > 86400000)
        if (updatables.length) Promise.all(products.map(p => this.updateProduct(p))).then(res => {
          // console.log(res.length, 'products updated')
          resolve(products.filter(p => p.seller !== 'FNAC').length ? false : true)
        })
        else resolve(false)
      } else resolve(false)
    })*/
  }

  scanCategory(category: any, force = false): Promise<boolean> {
    return Promise.resolve(false)
    // TODO: Reativar quando a API fnac voltar a funcionar
    /*if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const categoryIds = []
    const updatePromises = []
    const now = new Date().getTime()

    return new Promise(async resolve => {
      if (this.g.program.options.fnac && (force || !category.updatedTimestamp || (now - category.updatedTimestamp > (86400000 * 5)))) {
        if (category.sellerUrlsLines && category.sellerUrlsLines.length) {
          for (const u of category.sellerUrlsLines) {
            const id = u.sellerUrl.match(/(\D{1}\d{4,15})/)
            if (id.length) categoryIds.push(id[0])
          }

          const api = use<ApiService>(ApiService)
          for (const catId of categoryIds) {
            await api.get(`fnac/getByCategory?categoryId=${catId}`).pipe(first()).toPromise()
              .then(scan => {
                for (const prod of scan.products) {
                  updatePromises.push(this.fss.set('products', prod.sellerCode, {
                    ...prod,
                    seller: category.seller || 'FNAC',
                    subcategory: category.name,
                    category: category.parent,
                    id: prod.sellerCode,
                    apiFnac: true,
                    updatedTimestamp: now,
                    deliveryPeriod: 15
                  }, { silent: true }))
                }
              })
          }
          Promise.all(updatePromises).then(() => {
            this.fss.update('categories', category.id, { createdAt: category.createdAt, updatedTimestamp: now }, { silent: true })
            // console.log(updatePromises.length, 'category updated')
            resolve(true)
          }).catch(() => resolve(false))
          // console.log(categoryIds)
          // resolve(true)
        } else {
          console.log('this category no have seller Urls to capture products')
          resolve(false)
        }
      } else resolve(false)
    })*/
  }
}
