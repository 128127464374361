import { Injectable } from '@angular/core'
import * as UTF8 from "crypto-js/enc-utf8"
import * as AES from "crypto-js/aes"

let env: any = {}

const toJson = str => str instanceof Object ? JSON.stringify(str) : str
const fromJson = str => {
  try { return JSON.parse(str) } catch (e) { return str }
}
// const Cry.getCryKey = key => env.production ? btoa(
//   btoa(key.substr(0, Math.round(key.length / 2))).replace(new RegExp("="), '$')
//   + env._cryKey
//   + btoa(key.substr(Math.round(key.length / 3))).replace(new RegExp("="), '&')
// ).replace(new RegExp("=", 'g'), '') : key

@Injectable({ providedIn: 'root' })
export class Cry {

  private static _local: any = localStorage
  private static _session: any = sessionStorage
  env = (window as any).y4wenvs.ENV

  static getCryKey(key) {
    env = (window as any).y4wenvs.ENV
    return env.production ? btoa(
      btoa(key.substr(0, Math.round(key.length / 2))).replace(new RegExp("="), '$')
      + env._cryKey
      + btoa(key.substr(Math.round(key.length / 3))).replace(new RegExp("="), '&')
    ).replace(new RegExp("=", 'g'), '') : key
  }

  static setLocal(key: string, value: any, crypt = true): void {
    this._local.setItem(
      Cry.getCryKey(key),
      Cry.crypt(toJson(value), env.production ? true : crypt)
    )
  }

  static getLocal(key: string, crypt = true): any {
    const item = this._local.getItem(crypt ? Cry.getCryKey(key) : key)
    return item ? fromJson(Cry.decrypt(item, env.production ? true : crypt)) : null
  }

  static deleteLocal(key: string) {
    this._local.removeItem(key)
  }

  static set(key: string, value: any, crypt = true): void {
    this._session.setItem(
      Cry.getCryKey(key),
      this.crypt(toJson(value), env.production ? true : crypt)
    )
  }

  static get(key: string, crypt = true): any {
    const item = this._session.getItem(Cry.getCryKey(key))
    return item ? fromJson(item.startsWith('VTJG') ? this.decrypt(item, env.production ? true : crypt) : item) : null
  }

  static remove(key: string) {
    this._session.removeItem(Cry.getCryKey(key))
  }

  static removeLocal(key: string) {
    this._local.removeItem(Cry.getCryKey(key))
  }

  static clearAll(): void {
    this.clearLocal()
    this.clearSession()
  }

  static clearLocal(): void {
    this._local.clear()
  }

  static clearSession(): void {
    this._session.clear()
  }

  static crypt(str: string, crypt: boolean): string {
    return crypt ? btoa(AES.encrypt(typeof str !== 'string' ? String(str) : str, btoa(env._yesSalt) + btoa(env._yesKey + env._yesSalt)).toString()) : str
  }

  static decrypt(str: string, crypt: boolean): string {
    try {
      return crypt ? AES.decrypt(atob(str), btoa(env._yesSalt) + btoa(env._yesKey + env._yesSalt)).toString(UTF8) : str
    } catch (e) {
      if (e.message && e.message.indexOf("Failed to execute 'atob'") > -1) {
        return str
      }
      throw new Error(e.message)
    }
  }

}
