/* tslint:disable:radix */
import { KeyValue } from '@angular/common'
import camelCase from 'lodash-es/camelCase'
import isEqual from 'lodash-es/isEqual'
import merge from 'lodash-es/merge'
import { inject, NgZone } from '@angular/core'
import { Cry } from './cry.service'
import { GlobalService } from './global.service'

/**
 * Função que simplifica a injeção de services fora do constructor
 * @param token
 * @returns {T}
 */
export const use = <T>(token): T => {
  try {
    return inject<T>(token)
  } catch (e) {
    // console.info('**', token)
    return window['ctorinje'].get(token)
  }
}

export const chunkArray = (array, chunk) => {
  let tempArray = []
  for (let i = 0; i < array.length; i += chunk) tempArray = [...tempArray, array.slice(i, i + chunk)]
  return tempArray
}

export const pad = (str, max, fill?) => {
  str = str.toString()
  return str.length < max ? pad(fill || "0" + str, max, fill) : str
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const pascalCase = (string: string) => {
  const str = camelCase(string)
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const treeify = (collection: any[], nick: string = null, mobile: boolean = null) => {
  // create a name: node map
  const dataMap = collection.reduce((map, node) => ({ ...map, [node.name]: node }), {})
  // create the tree collection
  const treeData = []
  collection.forEach((node: any) => {
    // if (node.active && (!node[nick] || node[nick] === false)) {
    if (node.active && (!node.excludePrograms || !node.excludePrograms[nick])) {
      node.label = node.name
      // console.log(node)
      // add to parent
      const parent = dataMap[node.parent]
      if (parent) {
        parent.label = parent.name
        if (!parent.items) parent.items = []
        // create child collection if it doesn't exist
        if (node.parent !== 'HOME' && parent.parent !== 'HOME') node.routerLink = `/rewards/products/subcategory/${node.label}`
        // if (node.parent !== 'HOME' && parent.parent === 'HOME' && !node.items) node.command = () => console.log('command')
        if (node.parent !== 'HOME' && parent.parent === 'HOME' && !mobile) parent.items.push([node])
        else parent.items.push(node)
      } else {
        // parent is null or missing
        treeData.push(node)
      }
    }
  })
  return treeData[0].items
}

export const orientation = () => {
  if (window.matchMedia("(orientation: landscape)").matches) {
    return 'landscape'
  } else if (window.matchMedia("(orientation: portrait)")) {
    return 'portrait'
  }
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key])
      return false
  }
  return true
}

/**
 * Remove atributos iniciados por ums string pré-definida em um objecto simples
 * @param obj O objeto a ter os atributos removidos
 * @param startedBy A string inicial que define o atributo a ser removido
 *
 * Ex. de uso:
 * const obj = { name: 'walter', age: 25, _checked: true };
 * const newObj = clearFieldsStartedBy(obj, '_');
 * Isto remove o atributo _checked do objeto.
 *
 * Casos de uso:
 * - Remover atributos temporários usado para exibição de dados transformado no template
 */
export const clearFieldsStartedBy = (obj: any, startedBy: string): any => {
  if (obj instanceof Array) {
    obj = obj.map(o => clearFieldsStartedBy(o, startedBy))
    return obj
  }
  const tmp = {}
  for (const key in obj) if (!key.startsWith(startedBy)) tmp[key] = obj[key]
  return tmp
}

/**
 * Remove atributos com valores vazios ou nulos em um objecto simples
 * @param obj O objeto a ter os atributos removidos
 * @param removeNull TRUE se deseja também remover os NULL values
 *
 * Ex. de uso:
 * const obj = { name: 'walter', age: 25, checked: '' };
 * const newObj = clearFieldsBlank(obj);
 * Isto remove o atributo checked do objeto.
 *
 * Casos de uso:
 * - Remover atributos temporários usado para exibição de dados transformado no template
 */
export const clearFieldsBlank = (obj: any, removeNull: boolean = false): any => {
  if (obj instanceof Array) {
    obj = obj.map(o => clearFieldsBlank(o, removeNull))
    return obj
  }
  const tmp = {}
  for (const key in obj) if (obj[key] !== '') tmp[key] = obj[key]
  for (const key in obj) if (obj[key] === null && removeNull) delete tmp[key]
  return tmp
}

export function selectSegmentsAndSubSegments(segmentId: string, g: any, selecteds = new Set<string>()): Set<string> {
  if (!selecteds.has(segmentId)) selecteds.add(segmentId)
  const found = (g.get('segments') || []).filter(s => s.parentId === segmentId)
  if (found.length) {
    for (const f of found) {
      selecteds = selectSegmentsAndSubSegments(f.id, g, selecteds)
    }
  }
  return selecteds
}

/**
 * Filtra uma coleção de items pelos segmentso do utilizador atualmente logado
 * Para que esta função funcione corretamente, as coleções a serem filtradas devem conter os seguintes dois atributos em seus items:
 *  includedSegments: string[] - Um array com os ID dos segmentos que PODEM ter acesso ao item
 *  excludedSegments: string[] - Um array com os ID dos segmentos que NÃO PODEM ter acesso ao item
 * @param {any[]} items
 * @param {GlobalService} g
 * @returns {any[]}
 */
export function filterBySegments(items: any[], g: GlobalService): any[] {
  const showTo = [...(g.user?.segment ? [g.user.segment.id] : []), ...(g.user?.segments || [])]
  // console.log('#show', showTo)
  const forAll = items.filter(i => (!i.includedSegments || !i.includedSegments.length) && (!i.excludedSegments || !i.excludedSegments.length))
  const restriced = items.filter(i => (i.includedSegments && i.includedSegments.length) || (i.excludedSegments && i.excludedSegments.length))
    .map(i => ({
      ...i,
      _showTo: (i.includedSegments || []).map(s => selectSegmentsAndSubSegments(s, g)).reduce((a, b) => [...a, ...b], []),
      _hideTo: (i.excludedSegments || []).map(s => selectSegmentsAndSubSegments(s, g)).reduce((a, b) => [...a, ...b], [])
    }))
    .map(i => ({ ...i, _showTo: (!i.hasOwnProperty('showToChildren') || i.showToChildren) ? i._showTo : (i.includedSegments || []) }))
    .filter(i => i._hideTo.filter(h => showTo.includes(h)).length === 0 && i._showTo.filter(s => showTo.includes(s)).length > 0)
  // console.log('#resticed', restriced.map(({headline, _hideTo, _showTo}) => ({headline, _hideTo, _showTo})))
  return [...forAll, ...clearFieldsStartedBy(restriced, '.')]
}

/* descontinuado, apagar em breve */
export function filterBySegments2(items: any[], user: any) {
  console.log(items[0])
  return items
    .filter(p => !p.segment || !p.segment.length || p.segment.some(s => (user.segments || []).indexOf(s) > -1 || user.segment.id === s))
    .filter(p => !p.excludeSegments || !p.excludeSegments.length || p.excludeSegments.some(s => (user.segments || []).indexOf(s) === -1 && user.segment.id !== s))
}

/*
  *
  * @function constructObject
  * @param {file} file in dot notation ('a.b.c':'Hey', 'd.e.g': 'you')
  * @return {Object} - a nested object.
  * @example
  * const object = constructObject(file)
  * Returns the following:
  * a: {
  *   b: {
  *     c: 'hey'
  *   }
  * }
  * d: {
  *   e: {
  *     g: 'you'
  *   }
  * }
*/
export function objectToArray(obj, idName?, valueName?): any {
  const array = []
  for (const prop in obj) {
    if (obj[prop]) {
      const newObj = {}
      newObj[idName || 'id'] = prop
      newObj[valueName || 'value'] = obj[prop]
      array.push(newObj)
    }
  }
  return array
}

export const arrayToObject = (array, key, field?) => {
  return array.reduce((obj, item) => {
    obj[item[key]] = field ? item[field] : item
    return obj
  }, {})
}

// Get the nested object/key without the value
const getKey = (arr) => {
  const nestedObject = {}
  arr.reduce((o, s, index) => {
    if (index + 1 < arr.length) {
      return o[s] = {}
    }
  }, nestedObject) // eslint-disable-line
  return nestedObject
}

// Get the full object here
const getNested = (arr, val) => {
  const nestedObject = {}
  arr.reduce((o, s, index) => {
    if (index + 1 === arr.length) {
      return o[s] = val
    } else {
      return o[s] = {}
    }
  }, nestedObject) // eslint-disable-line
  return nestedObject
}

export const getNestedObject = (file) => {
  let final = {}
  const parsed = JSON.parse(JSON.stringify(file))
  Object.keys(parsed).forEach((key) => {
    const keysArr = key.split('.')
    if (keysArr.length > 1) {
      final = merge(final, getKey(keysArr), getNested(keysArr, parsed[key]))
    } else { final[keysArr[0]] = parsed[key] }
  })
  return final
}

export const excelDateToISOdate = (date) => {
  const d = new Date(Math.round((date - 25569) * 86400 * 1000))
  return d.toISOString()
}

// export default constructObject
export const originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return 0
}

// Order by ascending property value
export const valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.value.localeCompare(b.value)
}

// Order by descending property key
export const keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0)
}

export const filterObject = (obj, filter, filterValue) =>
  Object.keys(obj).reduce((acc, val) => (obj[val][filter] === filterValue ? { ...acc, [val]: obj[val] } : acc), {})

export const formatDate = (date) => {
  const d = new Date(date),
    year = d.getFullYear()
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()

  if (month.length < 2)
    month = '0' + month
  if (day.length < 2)
    day = '0' + day

  return [year, month, day].join('-')
}

/** setTimeout using NgZone, better for performance **/
export const timeOut = (fn: Function, ms: number = 10, zone = new NgZone({ enableLongStackTrace: false })) => zone.runOutsideAngular(() => setTimeout(() => zone.run(() => fn()), ms))

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
}

export const sleep = ms => {
  return new Promise(resolve => {
    timeOut(resolve, ms)
  })
}

export const deepFreeze = o => {
  Object.freeze(o)

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (o.hasOwnProperty(prop)
      && o[prop] !== null
      && (typeof o[prop] === "object" || typeof o[prop] === "function")
      && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop])
    }
  })

  return o
}

/**
 * Remove atributos iniciados por ums string pré-definida em um objecto complexo
 * @param obj O objeto a ter os atributos removidos
 * @param startedBy A string inicial que define o atributo a ser removido
 *
 * Ex. de uso:
 * const obj = { name: 'walter', age: 25, _checked: true };
 * const newObj = clearFieldsStartedBy(obj, '_');
 * Isto remove o atributo _checked do objeto.
 *
 * Casos de uso:
 * - Remover atributos temporários usado para exibição de dados transformado no template
 */
export const clearFieldsStartedByNested = (obj: any, startedBy: string): any => {
  let temp

  if (obj instanceof Array) {
    temp = []
    for (const item of obj) {
      temp.push(clearFieldsStartedByNested(item, startedBy))
    }
  } else {
    temp = {}
    for (const key in obj) {
      if (!key.startsWith(startedBy)) {
        if (typeof obj[key] === 'object') temp[key] = clearFieldsStartedByNested(obj[key], startedBy)
        else temp[key] = obj[key]
      }
    }
  }

  return temp
}

export const sumBy = (arr: any[], prop: string) => arr.map(i => i[prop]).reduce((acc, val) => acc + val, 0)

export const rndBetween = (from: number, to: number) => {
  const nativeArr = new Uint8Array(1)
  const crypto = window['crypto'] || window['msCrypto']
  crypto.getRandomValues(nativeArr)
  const num = [].slice.call(nativeArr)[0]
  return (num >= from && num <= to) ? num : rndBetween(from, to)
}

/**
 * Função recursiva que Transforma uma listagem de items interrelacionados por um parâmentro PARENT num arry compatível com PrimeNG TreeTable
 * @param items Array com a listagem
 * @param raw Uma cópia do array com a listagem
 * @param sortBy Nome do campo a ser ordenado Default: 'order'
 * @param first TRUE se é a primeira chamada do método
 * @param expand TRUE se é a tree deve permanecer aberta
 *
 * @return TreeNode[] Array padrão para uso com a TreeTable
 *
 * EX de uso:
 * this.parsedMenus = parseTableTree(menus, [...menus], 'name', true)
 */
export const parseTableTree = (items: any[], raw: any[], sortBy: string = 'order', first = false, expand = true, parentField = 'parentId') => {
  const items_ = first ? items.filter(m => !m[parentField]).sort((a, b) => a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1) : items
  return items_.map((m: any) => {
    const node = { data: { ...m }, label: m.label || m.name, children: [], expanded: expand }
    node.children = parseTableTree(raw.filter(i => i[parentField] === m.id).sort((a, b) => a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1), raw, sortBy, false, expand)
    return node
  })
}

/**
 * Função recursiva que transforma uma listagem de items interrelacionados por um parâmentro PARENT num arry compativem com PrimeNG TreeMenu
 * @param items Array com a listagem
 * @param raw Uma cópia do array com a listagem
 * @param firstParent String com ID do menu mais superior (program type)
 * @param sortBy Nome do campo a ser ordenado Default: 'order'
 *
 * @return TreeNode[] Array padrão para uso com a TreeMenu
 *
 * EX de uso:
 * this.parsedMenus = parseMenuTree(menus, [...menus], 'xyzxyz', 'name')
 */
export const parseMenuTree = (items: any[], raw: any[], firstParent: string = null, sortBy: string = 'order') => {
  const items_ = firstParent !== null ? items.filter(m => m.parentId === firstParent).sort((a, b) => a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1) : items
  return items_.map((m: any) => {
    const node = { label: m.name, data: m }
    if (m.route) node['routerLink'] = m.route // [m.route];
    if (m.icon) node['icon'] = m.icon
    if (raw.filter(i => i.parentId === m.id).length) {
      node['items'] = parseMenuTree(raw.filter(i => i.parentId === m.id).sort((a, b) => a[sortBy] === b[sortBy] ? 0 : a[sortBy] < b[sortBy] ? -1 : 1), raw, null, sortBy)
    }
    return node
  })
}

export const adjustSizes = () => {
  const display = { w: window.innerWidth, h: window.innerHeight, r: window.innerWidth / window.innerHeight }
  if (window.innerWidth > window.innerHeight) display.w = window.innerWidth; display.h = window.innerHeight; display.r = window.innerWidth / window.innerHeight
  // console.log(display)
  const content = { w: 1920, h: 1080, r: 16 / 9 }
  const view = { w: 0, h: 0, r: 0, mgX: 0, mgY: 0, mgR: 0, mgL: 0, fontSize: 16, ratio: '' }
  view.w = content.r > display.r ? display.w : display.w / display.r * content.r
  view.h = content.r > display.r ? display.w / content.r : display.h
  view.mgX = (display.w - view.w) / 2
  view.mgY = (display.h - view.h) / 2
  view.mgR = view.mgX * 2 > view.h / 5 ? view.h / 5 : view.mgX * 2
  view.mgL = view.mgX * 2 - view.mgR
  view.fontSize = display.r < content.r ? display.h / content.h * 16 : display.w / content.w * 16
  const el = document.documentElement
  for (const [key, value] of Object.entries(view)) {
    // console.log(`${key}: ${value}`)
    el.style.setProperty(`--${key}`, `${value}px`)
  }
  // view.ratio = (content.w / content.h).toString()
  // el.style.setProperty(`--ratio`, (content.w / content.h).toString())
  // this.g.set('view', view)
  return view
  // console.log(view)
}

export const validateNIF = nif => {
  // transforma o nif em String caso este venha como Number
  nif = `${nif}`
  // Remove espaços e outros caracteres não numéricos
  nif = nif.replace(/[^0-9]/g, '')

  // Verifica se o NIF tem o tamanho correto (9 dígitos)
  if (nif.length !== 9) {
    return false
  }

  // Calcula o dígito de controlo
  let soma = 0
  for (let i = 0; i < 8; i++) {
    soma += parseInt(nif[i]) * (9 - i)
  }

  const resto = soma % 11
  const digitoControlo = (11 - resto) % 11

  // Verifica se o dígito de controlo calculado coincide com o último dígito do NIF
  return digitoControlo === parseInt(nif[8])
}

/**
 * Baralha um array
 * @param {any[]} items
 * @returns {any[]}
 */
export const shuffle = (items: any[]) => {
  let currentIndex = items.length
  let tempValue
  let randIndex

  while (0 !== currentIndex) {
    randIndex = rndBetween(0, items.length - 1)
    currentIndex -= 1

    tempValue = items[currentIndex]
    items[currentIndex] = items[randIndex]
    items[randIndex] = tempValue
  }

  return items
}

export const verifyLoggedIn = (g, router) => {
  // test for SSR
  if (g.isBrowser) {
    timeOut(() => {
      const path = window.location.pathname
      const host = window.location.host || window.location.hostname
      if (path.indexOf('login/token') === -1 && path.indexOf('pub/') === -1) {
        console.log('### não é login', host)
        if (!g.user && !g.program.logins.anonymous) {
          if (path.indexOf('login') === -1 && path !== '/') {
            g.set('redirect', path, true)
          }
          router.navigate(['/login'])
          return
        }

        if (host.indexOf('admin.') > -1) {
          router.navigate(['/admin'])
          return
        }
        if (path === '/' && host.indexOf('.bo.') > -1 && g.user && ['admin', 'manager'].includes(g.user.role)) {
          router.navigate(['/admin'])
          return
        }
        if (path === '/' && host.indexOf('.bo.') > -1 && !g.user) {
          router.navigate(['/login'])
          return
        }
        if ((g.user && g.user.uid && g.user.active) /*|| g.program.logins.anonymous*/) {
          if (path === '/') router.navigate(['/', Cry.get('type'), 'home'])
        } /* else {
          if (path.indexOf('login') === -1 && path !== '/') g.set('redirect', path, true)
          router.navigate(['/login'])
        } */
      }
    }, 250)
  }
}

export const getLangPrefix = lang => lang.split('-')[0] === 'pt' ? '' : lang.split('-')[0]

/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info
 *
 **/
export const Base64 = {

  // private property
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  // public method for encoding
  encode: function (input) {
    let output = ""
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4
    let i = 0

    input = Base64._utf8_encode(input)

    while (i < input.length) {
      chr1 = input.charCodeAt(i++)
      chr2 = input.charCodeAt(i++)
      chr3 = input.charCodeAt(i++)

      // tslint:disable-next-line:no-bitwise
      enc1 = chr1 >> 2
      // tslint:disable-next-line:no-bitwise
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
      // tslint:disable-next-line:no-bitwise
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
      // tslint:disable-next-line:no-bitwise
      enc4 = chr3 & 63

      if (isNaN(chr2)) {
        enc3 = enc4 = 64
      } else if (isNaN(chr3)) {
        enc4 = 64
      }

      output = output +
        this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4)
    } // Whend

    return output
  }, // End Function encode


  // public method for decoding
  decode: function (input) {
    let output = ""
    let chr1, chr2, chr3
    let enc1, enc2, enc3, enc4
    let i = 0

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "")
    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++))
      enc2 = this._keyStr.indexOf(input.charAt(i++))
      enc3 = this._keyStr.indexOf(input.charAt(i++))
      enc4 = this._keyStr.indexOf(input.charAt(i++))

      // tslint:disable-next-line:no-bitwise
      chr1 = (enc1 << 2) | (enc2 >> 4)
      // tslint:disable-next-line:no-bitwise
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
      // tslint:disable-next-line:no-bitwise
      chr3 = ((enc3 & 3) << 6) | enc4

      output = output + String.fromCharCode(chr1)

      if (enc3 !== 64) {
        output = output + String.fromCharCode(chr2)
      }

      if (enc4 !== 64) {
        output = output + String.fromCharCode(chr3)
      }

    } // Whend

    output = Base64._utf8_decode(output)

    return output
  }, // End Function decode


  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    let utftext = ""
    string = string.replace(/\r\n/g, "\n")

    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n)

      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if ((c > 127) && (c < 2048)) {
        // tslint:disable-next-line:no-bitwise
        utftext += String.fromCharCode((c >> 6) | 192)
        // tslint:disable-next-line:no-bitwise
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        // tslint:disable-next-line:no-bitwise
        utftext += String.fromCharCode((c >> 12) | 224)
        // tslint:disable-next-line:no-bitwise
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        // tslint:disable-next-line:no-bitwise
        utftext += String.fromCharCode((c & 63) | 128)
      }

    } // Next n

    return utftext
  }, // End Function _utf8_encode

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    let string = ""
    let i = 0
    let c, c1, c2, c3
    c = c1 = c2 = 0

    while (i < utftext.length) {
      c = utftext.charCodeAt(i)

      if (c < 128) {
        string += String.fromCharCode(c)
        i++
      } else if ((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1)
        // tslint:disable-next-line:no-bitwise
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
        i += 2
      } else {
        c2 = utftext.charCodeAt(i + 1)
        c3 = utftext.charCodeAt(i + 2)
        // tslint:disable-next-line:no-bitwise
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
        i += 3
      }

    } // Whend

    return string
  } // End Function _utf8_decode

}
