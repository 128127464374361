import { ErrorHandler, Injectable } from '@angular/core'
import { first } from "rxjs/operators"
import { ApiService } from './api.service'
import { GlobalService } from './global.service'
import { use } from './utils'

let env: any
let BUILD: any
declare let window: any

@Injectable()
export class YesErrorHandler implements ErrorHandler {

  constructor(public g: GlobalService) {
    env = window.y4wenvs.ENV
    BUILD = window.y4wenvs.BUILD
    window.console.log = (...args) => null
    window.console.info = (...args) => null
    console.info(`## ${this.constructor.name}`)
  }

  handleError(error: Error) {
    // console.info('# handleError')
    try {
      const user = this.g.get('user')
      const err = {
        error: {
          message: error.message,
          stack: error.stack,
          name: error.name
        },
        path: window ? (window as any).location.href : '',
        user: user ? user.email : 'indefinido',
        nick: this.g.nick,
        timestamp: new Date().getTime(),
        createdAt: new Date().toISOString(),
        build: BUILD
      }

      if (env.production && error.message && error.message.indexOf('INTERNAL ASSERTION FAILED') === -1) {
        // const api = use<ApiService>(ApiService)
        const api = use<ApiService>(ApiService)
        api.post(`user/userAction?noAuthRequest`, { action: 'debug', err }, true).pipe(first()).toPromise()
      }
    } catch (e) {
      console.log('# handleError', e.message)
    }
  }
}
