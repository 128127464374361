import { Injectable } from '@angular/core'
import { NotifyService } from './notifications/notify.service'
import { TranslocoService } from "@ngneat/transloco"
import { UserService } from './user.service'
import { first } from 'rxjs/operators'
import { ApiService } from './api.service'
import { OrderStatus } from './enums'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { clearFieldsStartedBy, use } from './utils'

let env: any

@Injectable({ providedIn: 'root' })
export class OrdersService {
  type; nick; apiUrl; count: number; program: any
  private fss: FirestoreService2

  constructor(
    private notify: NotifyService,
    public g: GlobalService,
    private transloco: TranslocoService,
    private userService: UserService
  ) {
    console.info(`## ${this.constructor.name}`)
    env = (window as any).y4wenvs.ENV
    this.apiUrl = this.g.get('apiUrl')
  }

  placeOrder(order) {
    return new Promise((resolve, reject) => {
      const api: ApiService = use<ApiService>(ApiService)
      api.post('transactions/placeOrder', order, true).pipe(first()).toPromise().then((data) => {
        resolve(data)
      })
    })
  }

  async updateOrder(order: any): Promise<{ success: boolean, order: any }> {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    order = clearFieldsStartedBy(order, '_')
    delete order.cartLines
    let reasons = 0
    const allow = true
    let orderPointsChange = 0
    order.orderPoints = 0
    order.orderQty = 0
    order.orderEuros = 0

    for (const cartItem of order.cart) {
      const cancel = !cartItem.reason || cartItem.reason === '---' ? 1 : 0

      cartItem.qty = cartItem.qty * cancel
      cartItem.amtPoints = cartItem.qty * cartItem.points
      cartItem.amtEuros = cartItem.qty * cartItem.price
      // order.orderPoints += cartItem.qty * cartItem.points
      // order.orderEuros += cartItem.qty * cartItem.price
      orderPointsChange += cartItem.qty * cartItem.points
      // order.orderQty += cartItem.qty

      if (cartItem.reason && cartItem.reason !== '---') {
        cartItem.status = OrderStatus.CANCELED
        reasons++
      }
    }

    if (order.purchaseOrderDate !== '') order.status = order.status = OrderStatus.ALL_ORDERED
    // if (order.shipDate) order.status = order.status = OrderStatus.INVOICED
    if (order.cart.length === reasons) {
      order.status = OrderStatus.CANCELED
      order.deliveryDate = order.deliveryDate = OrderStatus.CANCELED
    }

    order.contactus = `${location.origin}/events/contactus/detail/${this.g.get('user').email}`
    order.orderDate = order.orderDate ? order.orderDate.substr(0, 10) : ''

    if (reasons !== 0) {
      order.program = order.program || {}
      order.program.templateId = this.g.program.emails['orderChange']
      order.subject = 'Alteração da encomenda '
    } /*else if (order.status === OrderStatus.INVOICED) {
      order.deliveryDate = 'aprox. 2-3 dias'
      order.program.templateId = this.g.program.emails['orderShipped']
      order.subject = 'Expedição da encomenda '
    }*/

    order.orderEuros = order.cart.reduce((acc, val) => acc + +val.amtEuros, 0)
    order.orderPoints = order.cart.reduce((acc, val) => acc + +val.amtPoints, 0)
    order.orderQty = order.cart.reduce((acc, val) => acc + +val.qty, 0)

    if (orderPointsChange !== 0) this.placeOrder(order)

    if (order.id && allow) {
      this.fss.merge('transactions', order.id, JSON.parse(JSON.stringify(order)), { nick: order.nick }).then(() => {
        this.notify.update('Os seus dados foram gravados.', 'btn-success', 3000)
        this.userService.recalcBalance(order)
      })
    } else {
      this.fss.merge(`transactions`, order.id || this.fss.createId, { ...JSON.parse(JSON.stringify(order)), active: order.id ? order.active : true }, { nick: order.nick }).then(() => {
        this.userService.recalcBalance(order)
      })
    }
    // if (env.id === 'yes4eu' && (order.status === OrderStatus.INVOICED || reasons !== 0)) this.sendConfirmationEmail(order)
    if (env.id === 'yes4eu' && reasons !== 0) this.sendConfirmationEmail(order)

    return { success: true, order }
  }

  sendConfirmationEmail(order) {
    console.log('### envou email de expediçao')
    return new Promise((resolve, reject) => {
      const _order = { ...order, status: this.transloco.translate(order.status) }
      _order.cart = _order.cart.map(i => ({ ...i, status: this.transloco.translate(i.status) }))
      const api: ApiService = use<ApiService>(ApiService)
      api.post('sendinblue/sendOrderConfirmation', _order, true).pipe(first()).toPromise()
        .then((docs) => { resolve(docs); this.notify.update('Os seus dados foram gravados. Email enviado', 'btn-success', 3000) })
        .catch((error: any) => { reject(error) })
    })
  }
}
