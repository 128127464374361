/* BOOTSTRAP css variables
--blue: #007bff;
--indigo: #6610f2;
--purple: #6f42c1;
--pink: #e83e8c;
--red: #dc3545;
--orange: #fd7e14;
--yellow: #ffc107;
--green: #28a745;
--teal: #20c997;
--cyan: #17a2b8;
--white: #fff;
--gray: #6c757d;
--gray-dark: #343a40;
--primary: #007bff;
--secondary: #6c757d;
--success: #28a745;
--info: #17a2b8;
--warning: #ffc107;
--danger: #dc3545;
--light: #f8f9fa;
--dark: #343a40;
--breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px;
--fontFamilySansSerif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
*/

export const THEME = {

  colors3: [
    { name: "blue", value: "#4f60d2" },
    { name: "lime", value: "#c4d600" },
    { name: "pink", value: "#e83e8c" },
    { name: "red", value: "#dc3545" },
    { name: "yellow", value: "#ffc107" },
    { name: "green", value: "#28a745" },
    { name: "cyan", value: "#17a2b8" },
    { name: "indigo", value: "#6610f2" },
    { name: "purple", value: "#6f42c1" },
    { name: "orange", value: "#fd7e14" },
    { name: "teal", value: "#20c997" },
    { name: "white", value: "#fff" },
    { name: "gray", value: "#6c757d" },
    { name: "gray-dark", value: "#343a40" },
    { name: "primary", value: "#4f60d2" },
    { name: "secondary", value: "#6c757d" },
    { name: "success", value: "#28a745" },
    { name: "info", value: "#17a2b8" },
    { name: "warning", value: "#ffc107" },
    { name: "danger", value: "#dc3545" },
    { name: "light", value: "#f8f9fa" },
    { name: "dark", value: "#343a40" },
    { name: "navBg", value: "#343a40" },
    { name: "navColor", value: "#fff" },
    { name: "loginBG", value: "#fff" },
    // { name: "signupBG", value: "#fff" },
  ],
  colors: {
    white: '#ffffff',
    gray: '#6c757d',
    'gray-dark': '#343a40',
    primary: '#4f60d2',
    primaryHover: '#4f60d2',
    secondary: '#6c757d',
    secondaryHover: '#6c757d',
    success: '#28a745',
    successHover: '#28a745',
    info: '#17a2b8',
    infoHover: '#17a2b8',
    warning: '#ffc107',
    warningHover: '#ffc107',
    danger: '#dc3545',
    dangerHover: '#dc3545',
    light: '#f8f9fa',
    lightHover: '#f8f9fa',
    dark: '#343a40',
    darkHover: '#343a40',
    navBg: '#343a40',
    navColor: '#ffffff',
    loginBG: '#ffffff',
    help: '#ffc107',
    helpHover: '#ffc107',
    ctaText: '#4f60d2',
    ctaBg: '#4f60d2',
    pageBg: '#ffffff'
  },
  colorsYES: [
    { name: "dark-blue", value: "#22223b" },
    { name: "gray-blue", value: "#4a4e69" },
    { name: "gray-magenta", value: "#9a8c98" },
    { name: "gray-red", value: "#c9ada7" },
    { name: "gray-green", value: "#f2f9e4" }
  ],
  layout: {
    'navbarHeight': '78px',
    'columnGap': '0.75rem',
    'navShadow': 'none',
    'boxSshadow': 'none',
    'easing': 'ease-out',
    'radiusSmall': '2px',
    'radius': '3px',
    'radiusLarge': '5px',
    'radiusRounded': '290486px',
    'speed': '86ms',
    // Flags */
    'variablerow': 'true',
  },
  typography: {
    'family-sans-serif': 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    'family-monospace': 'monospace',
    'render-mode': 'optimizeLegibility',
    'size-1': '3rem',
    'size-2': '2.5rem',
    'size-3': '2rem',
    'size-4': '1.5rem',
    'size-5': '1.25rem',
    'size-6': '1rem',
    'size-7': '0.75rem',
    'weight-light': '300',
    'weight-normal': '400',
    'weight-medium': '500',
    'weight-semibold': '600',
    'weight-bold': '700',
  },
  responsiveness: {
    // 'breakpoint-xs': '0',
    // 'breakpoint-sm': '576px',
    // 'breakpoint-md': '768px',
    // 'breakpoint-lg': '992px',
    // 'breakpoint-xl': '1200px',
    'gap': '32px',  // The container horizontal gap, which acts as the offset for breakpoints */
    'tablet': '769px',  // 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16 */
    'desktop': '960px + (2 * --gap)',  // 960px container + 4rem */
    'widescreen': '1152px + (2 * --gap)',  // 1152px container + 4rem */
    'fullhd': '1344px + (2 * --gap)',  // 1344px container + 4rem */,
  },
}
