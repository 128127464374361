import { GlobalService } from '../../global.service'
import { GatewayPartner, PaymentMethod, PaymentStatus } from '../payment.interface'
import { PaymentService } from '../payment.service'

export class PaymentBase {

  protected gatewayData: any = null
  protected method: PaymentMethod = null
  protected partner: GatewayPartner = null
  protected paymentID: string = null
  protected status: PaymentStatus = PaymentStatus.PENDING
  protected transaction: any = null
  protected value: number
  protected currency: 'EUR' | 'BRL'
  protected useLibrary = false
  protected hasRedirect = false
  protected _paymentSrv: PaymentService = null
  protected g: GlobalService
  // protected apiSrv: ApiService;

  constructor() {

  }

  protected saveLocal(): void {
    const local = JSON.stringify(this)
    // if (this.g.isBrowser) Cry.setLocal('paymentLocal', local)
  }

  fromJson(json: any): any {
    if (typeof json === 'object') {
      for (const prop in json) {
        if (json[prop] && typeof json[prop] !== 'function') this[prop] = json[prop]
      }
      return this
    } else throw new Error('An object was expected')
  }

  getGatewayData(): any {
    return this.gatewayData
  }

  getId(): string {
    return this.paymentID
  }

  getMethod(): PaymentMethod {
    return this.method
  }

  getPartner(): GatewayPartner {
    return this.partner
  }

  getPaymentErrorMessage(): string {
    return "payment_error_generic_1"
  }

  getPaymentLink(program: any): string { return '' }

  getStatus(): PaymentStatus {
    return this.status
  }

  getTransaction(): any {
    return this.transaction
  }

  getValue(): number {
    return this.value
  }

  isCreditCard(): boolean {
    return this.method === PaymentMethod.CC
  }

  isMBWay(): boolean {
    return this.method === PaymentMethod.MBW
  }

  isMultibanco(): boolean {
    return this.method === PaymentMethod.MB
  }

  setStatus(status: PaymentStatus): void {
    this.status = status
  }

  setTransaction(data: any): void {
    this.transaction = data
  }

  useLocalLibrary(): boolean {
    return this.useLibrary
  }

  // by interface

  // dispatchSuccessHook(api: any = null, data: any = null) { }
  // getConfig(): any {
  //   throw new Error("Method not implemented.");
  // }
  // getPayload(): any {
  //   throw new Error("Method not implemented.");
  // }
  // getTransactionStatus(): { paymentDate: string; partner: string; } {
  //   throw new Error("Method not implemented.");
  // }
  // hasFirstPayload(): boolean {
  //   throw new Error("Method not implemented.");
  // }
  // hasMBWPaid(): boolean {
  //   throw new Error("Method not implemented.");
  // }
  // setMBWPhone(phone: string): void {
  //   throw new Error("Method not implemented.");
  // }
  // setResult(data: any): void {
  //   throw new Error("Method not implemented.");
  // }
  // processResult(data: any = null) {
  //   throw new Error("Method not implemented.");
  // }
  // createInstance(payment: { partner: GatewayPartner; value: number; method: PaymentMethod; }, user: any, paymentConfig: any): PaymentBase {
  //   throw new Error("Method not implemented.");
  // }
  // createNewPayment(user: any): Promise<any> {
  //   throw new Error("Method not implemented.");
  // }
  // onRedirect(data: any): import("rxjs").Observable<{ success: boolean; transaction: any; }> {
  //   throw new Error("Method not implemented.");
  // }
  // getMBReference(): {entity: string, reference: string} {
  //   return {entity: '', reference: ''};
  // }

}
