import { AppInitService, customMissingHandler, NotifyService, TokenInterceptor, translocoLoader, YesErrorHandler, GlobalService, PipesModule } from '@y4w-core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, LOCALE_ID, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { registerLocaleData } from '@angular/common'
import ptPT from '@angular/common/locales/pt-PT'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { env } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { AdblockModalComponent } from '@shared/adblock-modal/adblock-modal.component'
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig } from '@ngneat/transloco'

export function init(appInitService: AppInitService, injector: Injector) {
  window['ctorinje'] = injector // torna o Angular Injector acessivel em qualquer sítio
  return () => appInitService.init()
}

registerLocaleData(ptPT)
@NgModule({
  declarations: [
    AppComponent,
    AdblockModalComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: env.production, registrationStrategy: 'registerWhenStable:30000' }),
        TranslocoModule,
        PipesModule
    ],
  providers: [
    AppInitService,
    DialogService,
    DynamicDialogRef, DynamicDialogConfig,
    { provide: APP_INITIALIZER, useFactory: init, deps: [AppInitService, Injector], multi: true },
    env.production ? { provide: ErrorHandler, useClass: YesErrorHandler } : ErrorHandler,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    translocoLoader,
    customMissingHandler,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['pt-PT'], // availableLangs.map(lang => lang.name),
        defaultLang: 'pt-PT', // defaultLang,
        fallbackLang: `pt-PT`,
        prodMode: env.production,
        reRenderOnLangChange: true
      } as TranslocoConfig
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(dialog: DialogService, notify: NotifyService, injector: Injector) {
    window['ctorinje'] = injector // torna o Angular Injector acessivel em qualquer sítio
    notify.defineDialogService(dialog)
    // window['timings'].push({ time: new Date().getTime(), name: 'start AppModule - constructor' })
  }

}
