import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { NotifyService } from './notifications/notify.service'
import { UserService } from './user.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { first } from 'rxjs/internal/operators/first'
import kebabCase from 'lodash-es/kebabCase'
import { ApiService } from './api.service'
import { AuthService } from './auth.service'
import { EmailService } from './email.service'
import { FirestoreService2 } from './firestore.service2'
import { GlobalService } from './global.service'
import { timeOut, use } from './utils'

let env: any
let BUILD: any

export interface IGUIAConfig {
  issuer: string
  redirectUri: string
  clientId: string
  secret: string
  responseType: string
  scope: string,
  segmentField: string,
  segmentFromGuia: boolean,
  notifyManagerOnNewUser: boolean,
  notifyManagerOnLoginError: boolean,
  notifyManagerOnLoginErrorEmail: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuiaService {

  private authCodeFlowConfig: any = null
  private segmentFromGuia = false
  private segmentField = ''
  private notifyManagerOnNewUser = false
  private notifyManagerOnLoginError = false
  private notifyManagerOnLoginErrorEmail: string
  private _config: any = null
  private fss: FirestoreService2

  constructor(
    private auth: AuthService,
    private router: Router,
    private notify: NotifyService,
    private g: GlobalService,
    private transloco: TranslocoService,
    private spiner: NgxSpinnerService,
    private userService: UserService
  ) {
    env = (window as any).y4wenvs.ENV
    BUILD = (window as any).y4wenvs.BUILD
    this.config()
  }

  private config() {
    const api = use<ApiService>(ApiService)
    api.post('open/d/action', {
      action: 'guiaConfig',
      nick: this.g.nick
    }, true).pipe(first()).toPromise().then((config: Partial<IGUIAConfig>) => {
      this.segmentFromGuia = config.segmentFromGuia || false
      this.segmentField = config.segmentField || ''
      this.notifyManagerOnNewUser = config.notifyManagerOnNewUser || false
      this.notifyManagerOnLoginError = config.notifyManagerOnLoginError || false
      this.notifyManagerOnLoginErrorEmail = config.notifyManagerOnLoginErrorEmail || undefined
      this.authCodeFlowConfig = {
        issuer: config.issuer,
        redirectUri: config.redirectUri,
        clientId: config.clientId,
        responseType: config.responseType || 'code',
        scope: config.scope || 'openid profile team email department',
        showDebugInformation: true,
        timeoutFactor: 0.01,
      }
      this._config = config
    })
  }

  login() {
    this.spiner.show()
    window.location.href = encodeURI(`${this.authCodeFlowConfig.issuer}/authorize?client_id=${this.authCodeFlowConfig.clientId}&redirect_uri=${this.authCodeFlowConfig.redirectUri}&response_type=code&scope=${this.authCodeFlowConfig.scope}`)
  }

  requestToken(code: string) {
    this.spiner.show()
    const api = use<ApiService>(ApiService)
    api.post('user/userAction', {
      action: 'validateOidToken',
      nick: this.g.nick, // body, headers: options.headers, url: this.authCodeFlowConfig.issuer
      code
    }, true).subscribe(ok => {
      if (ok.success) {
        if (!env.production) console.log(ok.data)
        this.saveDebug({ message: `# requestToken `, stack: JSON.stringify(ok.data.user || {}) })
        this.validateOpenIDUserToken(ok.data.user).then(u => {
          timeOut(() => {
            this.router.navigate([`/${this.g.program.type}/home`])
            this.userService.recalcBalance({ userCode: ok.data.user.sub, email: ok.data.user.email, displayName: ok.data.user.name, id: null, nick: this.g.nick })
            this.spiner.hide()
          }, 500)
        }).catch(e => {
          this.auth.signOut()
          if ((e.message || e) === 'guia_auth_error_missing_email') {
            this.notify.alert(this.transloco.translate('guia_auth_error_missing_email'), `
          <span>Como registar o e-mail em GUIA:</span>
          <ol>
          <li>Aceder a GUIA <a href="https://guia.corporativo.pt/GUIA/Home.aspx" target="_blank">https://guia.corporativo.pt/GUIA/Home.aspx</a></li>
          <li>Selecionar A minha identidade</li>
          <li>Selecionar Contactos Adicionais</li>
          <li>Clicar nas reticências no separador Pessoal ou Corporativo de acordo com o tipo de e-mail registado no ${this.g.program.name}</li>
          <li>Selecionar Novo Contacto</li>
          <li>Em Tipo de Contacto selecionar e-mail</li>
          <li>No campo Nome do Contacto selecionar o tipo de e-mail</li>
          <li>No campo Valor registar o e-mail de acordo com e-mail registado no ${this.g.program.name}</li>
          <li>Selecionar o campo contacto default</li>
          <li>Autorizar o tratamento dos dados pessoais</li>
          </ol>
          <span>Caso surja alguma dúvida no registo de e-mail, fala com o teu gestor/supervisor/chefe de equipa ou envia um e-mail para <a href="mailto:dinamizacao.comercial@nos.pt">dinamizacao.comercial@nos.pt</a>.</span>
          `, 'OK', 'lg', { width: '100%', 'max-width': '450px' })
          }
          timeOut(() => this.notify.update(this.transloco.translate(e.message || e), 'btn-danger', 7000), 250)
          this.spiner.hide()
        })
      } else timeOut(() => this.notify.update(this.transloco.translate(ok.error), 'btn-danger', 7000), 250)
    }, er => {
      this.spiner.hide()
      console.log(er.message)
      this.saveDebug({ message: `# requestToken `, stack: JSON.stringify(er) })
      timeOut(() => this.notify.update(this.transloco.translate(er.message || er), 'btn-danger', 7000), 250)
    })
  }

  validateOpenIDUserToken(oidUser: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    // console.log('# oidUser', oidUser)
    if (!oidUser || !oidUser.name || !oidUser.role || !oidUser.sub || /*!oidUser.team || !oidUser.department ||*/ (this.segmentFromGuia && !oidUser[this.segmentField])) {
      this.saveDebug({ message: `# guia_auth_error_missing_params `, stack: JSON.stringify(oidUser) })
      this.notifyManagerLoginError(oidUser)
      return Promise.reject('guia_auth_error_missing_params')
    }
    if (!oidUser.email) {
      this.saveDebug({ message: `# guia_auth_error_missing_email `, stack: JSON.stringify(oidUser) })
      return Promise.reject('guia_auth_error_missing_email')
    }
    const api: ApiService = use<ApiService>(ApiService)
    oidUser.email = oidUser.email.toLowerCase()
    return new Promise((resolve, reject) => {
      api.post('user/userAction?noAuthRequest=true', {
        action: 'validateToken',
        uid: btoa(oidUser.email + oidUser.email.split('@')[1]).replace(/=/g, ''),
        email: oidUser.email
      }, true).pipe(first()).toPromise().then(ok => {
        if (ok.success) {
          this.saveDebug({ message: `# validateToken `, stack: ok.token })
          this.authWithOpenIDFirebase(ok.token, oidUser)
            .then(async (user: any) => {
              if (!user.error) {
                // migra utilizadores anteriores ao GUIA
                await api.post('users/migrateEmailToProvider', {
                  uid: user.uid,
                  email: oidUser.email,
                  userCode: oidUser.sub,
                  nick: this.g.nick,
                }, true).pipe(first()).toPromise().then(_ok => console.log(_ok))

                const updatedUser = await this.fss.get('users', user.uid, { api: true }).pipe(first()).toPromise()
                // console.log('#updateUser', updatedUser)

                if (updatedUser) this.notifyManager(updatedUser)
                this.saveDebug({ message: `# authWithOpenIDCredential `, stack: JSON.stringify(user) })
                api.post('users/updateUserAuth', { uid: user.uid, displayName: oidUser.name || '', email: oidUser.email, nick: this.g.nick }, true)
                  .pipe(first()).toPromise()
                  .then(ok => {
                    if (ok && ok.error) reject(ok.error)
                    else {
                      user.firstLogin = user.firstLogin || new Date().toISOString()
                      user.lastLogin = new Date().toISOString()
                      this.fss.update('users', user.uid || user.id, { createdAt: user.createdAt, firstLogin: user.firstLogin, lastLogin: user.lastLogin }, { silent: true }).then()
                      resolve(user)
                    }
                  })
                  .catch(_er => {
                    console.log('#updateUserAuth', _er.message)
                    this.notify.update(this.transloco.translate(_er.message), 'btn-danger', 6000)
                    reject(_er.message)
                  })
              } else {
                this.saveDebug({ message: `# authOpenIDWithFirebase `, stack: JSON.stringify(user) })
                this.notify.update(this.transloco.translate(user.error), 'btn-danger', 6000)
                resolve(user)
              }

            })
            .catch(async (error) => {
              this.saveDebug({ ...error, message: `# authWithOpenIDFirebase ${error.message}` })
              this.notify.update(error.message, 'btn-danger', 3000)
              reject(error)
            })
        } else if (ok.error) {
          this.notify.update(ok.error, 'btn-danger', 3000)
          reject(ok.error)
        }
      }).catch(err => {
        this.saveDebug({ ...err, message: `# validateToken ERROR ` + err.message })
      })
    })
  }

  authWithOpenIDFirebase(token: string, oidUser: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const api = use<ApiService>(ApiService)
    return this.auth.afAuth.signInWithCustomToken(token)
      .then(async (result) => {
        oidUser.email = oidUser.email.toLowerCase().trim()
        const dbUser = await this.fss.get('users', result.user.uid, { api: true }).pipe(first()).toPromise()
        let segment = null
        let prospect = null

        // verify user segment
        if (this.segmentFromGuia && oidUser[this.segmentField]) {
          const segments = await this.fss.list('segments', { api: true }).pipe(first()).toPromise()
          segment = segments.find(s => s.name.toLowerCase() === oidUser[this.segmentField].toLowerCase()) || null
          if (!segment) {
            segment = {
              id: this.fss.createId,
              active: true,
              hasRank: true,
              name: oidUser[this.segmentField],
              uniq: kebabCase(oidUser[this.segmentField]), // .toLowerCase(),
              nick: this.g.nick
            }
            await this.fss.set('segments', segment.id, segment)
          }
        }

        // verify user existing in DB
        if (!dbUser) {
          const newUser: any = {
            id: result.user.uid,
            uid: result.user.uid,
            displayName: oidUser.name || oidUser.email.split()[0] || '',
            name: oidUser.name || '',
            firstName: (oidUser.name || '').split(' ')[0],
            email: oidUser.email,
            emailVerified: result.user.emailVerified || true,
            isAnonymous: result.user.isAnonymous || false,
            photo: result.user.photoURL || null,
            cart: [],
            wishlist: [],
            addresses: [],
            balance: 0,
            nick: this.g.nick,
            active: true,
            role: oidUser.role ? oidUser.role.toLowerCase() : 'user',
            segment: this.segmentFromGuia && segment ? segment : null,
            segments: [],
            department: oidUser.department || null,
            team: oidUser.team || null,
            provider: 'OpenID',
            providerId: oidUser.sub || '',
            userCode: oidUser.sub || undefined,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            firstLogin: new Date().toISOString(),
            lastLogin: new Date().toISOString(),
            guiaMigration: false,
            approve: !this.notifyManagerOnNewUser,
            hasGuiaMigrated: true
          }

          // verify if program lookups
          if (this.g.program.logins.lookup) {
            // prospects = await this.fss.list('prospects', { api: true, where: [['used', '==', false]] }).pipe(first()).toPromise()
            // prospect = prospects.find(p => p.email && p.email.toLowerCase() === oidUser.email.toLowerCase()) || null
            prospect = await api.post('db/action', {
              action: 'findProspect',
              nick: this.g.nick,
              email: oidUser.email.toLowerCase()
            }, true)
            if (prospect && prospect.active && !prospect.used) {
              newUser.segment = prospect.segment || null
              newUser.segments = prospect.segments || []
              newUser.displayName = newUser.displayName || prospect.displayName || ''
              newUser.role = prospect.role || 'user'

              // cria o user via cripted API
              await api.post('db/action', {
                action: 'createUser',
                id: result.user.uid,
                nick: this.g.nick,
                data: newUser
              }, true).pipe(first()).toPromise().then(() => {
                this.fss.merge('prospects', prospect.id, { used: true })
              })
              // metodo de criação antigo
              // await this.fss.set('users', result.user.uid, newUser).then(() => {
              //   this.fss.merge('prospects', prospect.id, { used: true })
              // })
            } else return { error: 'user_not_allowed_or_has_registed' }
          } else {
            // cria o user via cripted API
            await api.post('db/action', {
              action: 'createUser',
              id: result.user.uid,
              nick: this.g.nick,
              data: newUser
            }, true).pipe(first()).toPromise().then(() => {
              // this.notifyManager(newUser)
            })
            // meto de criação antigo
            // await this.fss.set('users', result.user.uid, newUser).then(() => {
            //   // this.notifyManager(newUser)
            // })
          }

        } else if (!dbUser.active) {
          this.auth.signOut(false)
          return { error: 'user_not_registed_or_not_activated' }
        }

        if (this.segmentFromGuia && dbUser && dbUser.segment && dbUser.segment.id && dbUser.segment.id !== segment.id) {
          await this.fss.update('users', dbUser.uid || dbUser.id, { segment, createdAt: dbUser.createdAt }, { silent: true })
        }

        // console.log('#result', result.user);
        return result.user
      })
      .catch((error) => {
        this.saveDebug({ message: `# authWithOpenIDCredential `, stack: JSON.stringify(error.stack) })
        console.log('#error', error)
      })
  }

  saveDebug(error: any) {
    const user = this.g.get('user')
    const err = {
      error: {
        message: error.message,
        stack: error.stack || '',
        name: error.name || ''
      },
      path: window.location.href,
      user: user ? user.email : 'indefinido',
      nick: this.g.nick,
      timestamp: new Date().getTime(),
      createdAt: new Date().toISOString(),
      build: BUILD
    }
    if (!env.production) console.log(error.message)

    const api = use<ApiService>(ApiService)
    api.post(`user/userAction?noAuthRequest`, { action: 'debug', err }, true).pipe(first()).toPromise()
  }

  notifyManager(newUser: any) {
    if (!this.fss) this.fss = use<FirestoreService2>(FirestoreService2)
    const email = use<EmailService>(EmailService)
    setTimeout(async () => {
      const user = await this.fss.get('users', newUser.id || newUser.uid, { api: true }).pipe(first()).toPromise()
      if (user && this.notifyManagerOnNewUser && (!user.approve || !user.segment)) {
        console.log('# notifyManager')
        email.sendPlain({
          subject: 'Novo utilizador a aprovar em ' + this.g.program.name,
          name: this.g.program.name + ' Manager',
          user: this.g.program.name + ' Manager',
          email: this.g.program.manager,
          bcc: 'waltergandarella@yesmkt.com',
          message: `
      Há um novo utilizador registado que necessita de sua aprovação:<br><br>
      <strong>${newUser.displayName || newUser.name || 'Nome não definido'} - ${newUser.userCode} - ${newUser.email}</strong><br><br>
      Por favor, vai até ${this.g.program.url}/admin/tables/user-approval para aprovar novos utilizadores.
      `,
          programImg: this.g.program.images.logoMobile
        }, email.emailProgram()).subscribe(() => console.log('OK'))
      }
    }, 1000 * 60)
  }

  notifyManagerLoginError(oidUser: any) {
    if (this.notifyManagerOnLoginError && this.notifyManagerOnLoginErrorEmail) {
      try {
        const email = use<EmailService>(EmailService)
        timeOut(async () => {
          console.log('# notifyManager')
          let message = `
          O GUIA negou acesso a este utilizador:<br><br>
          <strong>Nome:</strong> ${oidUser.name || ''}<br>
          <strong>User:</strong> ${oidUser.sub || ''}<br>
          <strong>Email:</strong> ${oidUser.email || ''}<br>
          <strong>role:</strong> ${oidUser.role || ''}<br>
        `
          if (this.segmentFromGuia && !oidUser[this.segmentField]) message += `<strong>${this.segmentField}</strong>: ${oidUser[this.segmentField] || ''}<br><br>`

          email.sendPlain({
            subject: 'GUIA não deu acesso ao utilizador ' + oidUser.sub || '',
            name: this.g.program.name + ' Manager',
            user: this.g.program.name + ' Manager',
            email: this.notifyManagerOnLoginErrorEmail,
            bcc: 'waltergandarella@yesmkt.com',
            message,
            programImg: this.g.program.images.logoMobile
          }, email.emailProgram()).subscribe(() => console.log('OK'))
        }, 1000 * 60)
      } catch (e) {
        // evita o erro
      }
    }
  }
}

// Node SAML
// https://github.com/node-saml/node-saml

// OneLogin clientID: 7bb00f70-a94e-013a-6cd5-0ad5aca7c98b208833
// OneLogin secret: 2b7db0e8af2092a267fa49245694d8263c964b4f1ed5688df7968f032ec1f82d

// API
// clientID: 677d01bfe971a5e4bc051860a0e9423dc58f33e453d0a8fa6b9cddf8025074b1
// secret: 1fc65410cd621434e59522772bd38c711ab5ffc077706e5f44bd9abf650d2a1c

// user teste NOS Tecmais
// username: wgneto
// pass: Tecmais2022!
// issuer: https://authdev.co.nos.pt/auth?client_id=Tecmais&redirect_uri=http%3A%2F%2Flocalhost%3A4500%2Flogin%2Ftoken%2Foid&response_type=code&scope=openid%2Bprofile%2Bemail
// curl -X POST -d grant_type=authorization_code -d 'redirect_uri=http://localhost:4500/login/token/oid' -d code=80ca2d9a75a453d56722fcf4ec639f488f68f4aa2dabc9e3c978815862516582 -u 'tecmais:Teste123!' 'https://authdev.co.nos.pt/oauth2/token'
// login https://authdev.co.nos.pt/oauth2/authorize?client_id=tecmais&redirect_uri=https://tecmais.yesmktg.net/login/token/oid&response_type=code&scope=openid%20profile%20email
